import React from 'react';

import { IoChevronUp, IoExtensionPuzzle } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import styles from './tutorListDesktop.module.scss';
import { IQuiz } from '../../../types/quiz';
import defaultPicture from '../../../assets/images/plante.jpg';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import FilterSearchParam from '../../FilterSearchParam';
import { getApiPicture } from '../../../utils';

const Row = ({ quiz, status }: { quiz: IQuiz, status: string }) => {
  const tags = useTagsToOptionsLang(quiz?.tags || []);
  const picture = quiz?.picture?.path ? getApiPicture(quiz?.picture?.path) : defaultPicture;
  const navigate = useNavigate();
  const tag = tags.length > 0 ? tags[0] : null;

  const onRowClick = (e: any, id: string) => {
    e.preventDefault();
    navigate(`/tutor-quiz/edit/${id}`);
  };

  return (<div
      onClick={(e) => onRowClick(e, quiz._id)}
      className={styles.row}
    >
    <div className={styles.col}>
      <div className={styles.picture}>
        {picture && <img src={picture} />}
      </div>
    </div>
    <div className={styles.col}>
      <p>{quiz.name}</p>
    </div>
    <div className={styles.col}>
      <p>{quiz.learners.length}</p>
    </div>
    <div className={styles.col}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
    <div className={styles.col}>
      <p>{quiz.createdAt && format(new Date(quiz.createdAt), 'dd.MM.yyyy')}</p>
    </div>
    <div className={styles.col}>
      <p>{status}</p>
    </div>
  </div>);
};

const TutorQuizListDesktop = ({
  quiz,
  tags,
  title,
  status,
}: {
  quiz: IQuiz[],
  tags: any,
  status: any,
  title: string,
}) => {
  const { t } = useTranslation('createQuiz');

  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((d: any) => d.authReducer);

  const { order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const labels = [
    {
      label: t('label.cover'),
    },
    {
      label: t('label.name'),
      sortKey: 'name',
    },
    {
      label: t('label.count'),
    },
    {
      label: t('label.tags'),
    },
    {
      label: t('label.date'),
    },
    {
      label: t('label.status'),
    },
  ];

  return (
    <div className={styles.desktop}>
    <div className={styles.header}>
      <h1>{title}</h1>
      <div className={styles.actions}>
        <Link to='/tutor-quiz/create' className={styles.create}>
          <IoExtensionPuzzle />{t('create')}
        </Link>
      </div>
    </div>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <FilterSearchParam
            label={t('filters.tags')}
            name='tag'
            options={tags || []}
          />
        </div>
        <div className={styles.filter}>
          <FilterSearchParam
            label={t('filters.status')}
            name='status'
            options={status || []}
          />
        </div>
      </div>
      {quiz.length > 0
      && <div className={styles.content}>
        <div className={`${styles.row} ${styles.label}`}>
          {labels.map((l: any) => <div key={l.label}
            className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
            onClick={() => handleSorting(l?.sortKey)}
          >
            {l?.sortKey === sortKey && <div className={styles.icon}>
              <IoChevronUp />
            </div>
            }
            <p>{l.label}</p>
          </div>)}
        </div>
        <div className={`${styles.list} ${user?.role === 'Learner' ? styles.learner : ''}`}
        >
          {quiz.map((q: IQuiz) => (<Row
            key={q._id}
            quiz={q}
            status={status?.find((s: any) => s.value === q?.status)?.label}
          />))}
        </div>
      </div>
      }
  </div>
  );
};
export default TutorQuizListDesktop;
