import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { InputOption } from '../../../types/userQuiz';
import InputText from '../../../lib/HooksFormFields/InputText';
import styles from './InputsOptions.module.scss';

const InputsOptions = ({
  inputs,
  control,
  disabled = false,
  correction,
  prevAnswer,
}:{
  inputs: InputOption[],
  answerType: string,
  control: any,
  prevAnswer: any;
  disabled: boolean,
  correction: string[],
}) => {
  const { settings } = useSelector((s: any) => s.userQuizReducer);

  const getCorrection = (property: any) => {
    if (correction && prevAnswer?.inputs) {
      let correctAnwser = '';
      correction.forEach((c) => {
        if (Array.isArray(c[property])) {
          const firstElement = c[property];
          correctAnwser = firstElement && firstElement[0];
        }
      });
      return {
        correctAnwser,
        className: correctAnwser && correctAnwser?.toLowerCase() === prevAnswer?.inputs[property]?.toLowerCase() ? 'correct' : 'wrong',
      };
    }
    return {
      className: 'login',
    };
  };

  const reorderedInputs : any[] = useMemo(() => {
    // Separate the object with label "Noms communs" and the rest of the array
    const commonNamesItem = inputs.find((item) => item.label === 'Noms communs');
    if (!commonNamesItem) {
      return inputs || [];
    } const otherItems = inputs.filter((item) => item.label !== 'Noms communs');

    // Reorder the array with the "Noms communs" item last
    return [...otherItems, commonNamesItem];
  }, [inputs]);

  return (
    <div className={`${styles.options} ${styles[settings.font]} ${disabled ? styles.disabled : ''}`}>
      {reorderedInputs?.map((input : InputOption) => <div
        key={input?._id}
        className={styles.field}
        >
          <InputText
          name={`inputs.${input.property}`}
          label={input.label}
          control={control}
          className={getCorrection(input.property)?.className}
        />
        {getCorrection(input.property)?.correctAnwser && getCorrection(input.property)?.className === 'wrong'
          && <p className={styles.correction}>{getCorrection(input.property)?.correctAnwser}</p>
        }
      </div>)}
    </div>
  );
};

export default InputsOptions;
