import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'react-hook-form';
import { IUser } from '../../../types/users';
import InputFile from '../../InputFile';
import pen from '../../../assets/images/pen.svg';
import {
  Checkbox, ErrorField, InputEmail, InputText,
} from '../../../lib/HooksFormFields';
import styles from './userForm.module.scss';
import { useUpdateUser } from '../../../hooks/users';
import SelectMultiple from '../../SelectMultiple';
import { IOption } from '../../../types/options';

const UserForm = ({
  user = null,
  tags = [],
  groups = [],
  control,
  errors,
  apiError,
  watch,
}: {
  user: IUser | null,
  control: any,
  errors: any,
  apiError: any,
  tags?: IOption[] | null,
  groups?: IOption[] | null,
  watch?: any
}) => {
  const { t } = useTranslation('users');
  const {
    mutateAsync: updateUser,
  } = useUpdateUser();

  const emailUsed = apiError?.response?.data?.error === 'That email address is already in use.';
  return (
    <>
    {user?.profile
      && <div className={styles.name}>
        <h1>{user?._id ? `${user.profile.firstName} ${user.profile.lastName}` : t('user.new')}</h1>
        <p>{user?.email}</p>
      </div>
    }
    <div className={styles.form}>
    {user?._id
      && <section>
        <div className={styles.row}>
          <div className={styles.field}>
            <div className={styles['picture-container']}>
              <InputFile
                submit={(picture: string) => updateUser({ _id: user._id, picture }) }
                id={'profile.picture'}
              >
                {user?.picture?.path
                  ? <>
                    <div className={styles.picture}>
                      <div className={styles.icon}>
                        <img src={pen} alt="edit"/>
                      </div>
                      <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}`}
                        className={styles.img}
                      />
                  </div>
                </> : <div className={`${styles.picture} ${user?.role === 'Admin' ? styles.admin : ''}`}>
                  <div className={styles.icon}>
                    <img src={pen} alt="edit"/>
                  </div>
                  <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                </div>}
              </InputFile>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.tag}>
              <SelectMultiple
                name='tags'
                label={t('user.label.tags')}
                isIcon
                control={control}
                options={tags || []}
              />
            </div>
            <div className={styles.tag}>
              <SelectMultiple
                name='groups'
                label={t('user.label.newGroup')}
                isIcon
                control={control}
                isCreatable
                options={groups || []}
              />
            </div>
            {watch('role') === 'Learner'
            && <div className={styles.check}>
              <div className={styles.containerField}>
                <Checkbox
                  name="isSpecifik"
                  control={control}
                  label={t('user.label.isSpecifik')}
                  rules={{
                  }}
                />
              </div>
            </div>
          }
          </div>
        </div>
      </section>
    }
    <section>
      <div className={styles.row}>
        <div className={styles.field}>
          <InputText
            label={t('user.label.firstName')}
            control={control}
            name="profile.firstName"
            className='primary'
            rules={{ required: t('requiredField', { ns: 'global' }) }}
          />
          {get(errors, 'profile')?.firstName?.message
            && <ErrorField message={get(errors, 'profile')?.firstName?.message} />}
        </div>
        <div className={styles.field}>
          <InputText
            label={t('user.label.lastName')}
            control={control}
            name="profile.lastName"
            className='primary'
            rules={{ required: t('requiredField', { ns: 'global' }) }}
          />
          {get(errors, 'profile')?.lastName?.message
            && <ErrorField message={get(errors, 'profile')?.lastName?.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.field}>
          <InputText
            label={t('user.label.phone')}
            control={control}
            name="phone"
            className='primary'
          />
        </div>
        <div className={styles.field}>
          <InputEmail
            label={t('user.label.email')}
            control={control}
            name="email"
            className='primary'
            required
          />
          {emailUsed && <ErrorField message={t('user.emailUsed')} />}
          {get(errors, 'email')?.message
            && <ErrorField message={get(errors, 'email')?.message} />}
        </div>
      </div>
    </section>

  </div>
  </>
  );
};

export default UserForm;
