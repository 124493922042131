import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { FaArrowLeft } from 'react-icons/fa6';

import EditMode from './edition-mode';
import PreviewMode from './preview-mode';
import styles from './edit.module.scss';
import HeaderMobile from '../../../components/HeaderMobile';

const Edit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['global', 'createQuiz']);

  const back = {
    label: t('back', { ns: 'createQuiz' }),
    url: '/tutor-quiz',
  };

  return (
    <div className={styles.edit}>
      <HeaderMobile back={back} />
      <div className={styles.page}>
        <div className={styles.header}>
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <FaArrowLeft /> {t('back', { ns: 'global' })}
          </a>
        </div>
        <Routes>
          <Route path='preview' element={<PreviewMode />} />
          <Route path='*' element={<EditMode />} />
        </Routes>
      </div>
    </div>
  );
};

export default Edit;
