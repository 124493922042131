import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { IoExtensionPuzzle } from 'react-icons/io5';
import filterIcon from '../../../assets/images/filter.svg';
import styles from './TutorQuizListMobile.module.scss';

import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';

import { IOption } from '../../../types/options';
import { IQuiz } from '../../../types/quiz';
import QuizList from '../../QuizzComponents/QuizList';

const TutorQuizListMobile = ({
  quiz,
  tags,
  status,
}: {
  quiz: IQuiz[],
  tags: IOption[],
  status: IOption[],
}) => {
  const { t } = useTranslation('createQuiz');
  const [searchParams, setSearchParams] = useSearchParams();

  const { search = '', tag = '' } = Object.fromEntries(searchParams);
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(!!search || !!tag);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  return (
    <div className={styles.mobile}>
    <div className={styles.btn}>
      <Link to='/tutor-quiz/create' className={styles.create}>
        <IoExtensionPuzzle />{t('create')}
      </Link>
    </div>
    <div
      className={styles.filters}
      ref={filtersRef}
    >
      <div className={styles.btns}>
        <button
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={filterIcon} alt="filter" />
        </button>
      </div>
      {isOpen
        && <div className={styles.panel}>
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
          <div className={styles['filters-row']}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.tags')}
                name='tag'
                options={tags || []}
              />
            </div>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.status')}
                name='status'
                options={status || []}
              />
            </div>
          </div>
        </div>
      }
    </div>
    <div className={styles.list}
      style={isOpen ? {
        height: `calc(100% - ${filtersRef.current?.getBoundingClientRect().height}px)`,
      } : {
        height: 'calc(100% - 83px)',
      }}
    >
    <QuizList list={quiz} isTutor/>
    </div>
  </div>
  );
};

export default TutorQuizListMobile;
