import axios from 'axios';

const postSessionLogin = async (data: any): Promise<any> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/exam-sessions/login`,
    data,
  );
  console.log(response);
  return response.data;
};

export default postSessionLogin;
