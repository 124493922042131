import React, { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderMobile from '../../components/HeaderMobile';
import backIcon from '../../assets/images/back-grey.svg';
import styles from './user.module.scss';
import {
  useCreateUser, useGetUser, useGetUsersList, useUpdateUser,
} from '../../hooks/users';
import UserForm from '../../components/form/UserForm';
import { StyledSelect } from '../../lib/HooksFormFields';
import useOptionsLang, { IOptionTranslated } from '../../hooks/useOptionsLang';
import useTagsToOptionsLang, { ITag } from '../../hooks/useTagsToOptionsLang';
import { SET_TOAST } from '../../actions/types';

const User = () => {
  const { id } = useParams();
  const organisation = localStorage.getItem('organisation');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user } = useGetUser(id);
  const {
    data: list = {},
  } = useGetUsersList(organisation ? { organisations: [organisation] } : {});
  const {
    mutateAsync: createUser,
    error: createUserError,
  } = useCreateUser();

  const {
    mutateAsync: updateUser,
    error: errorUpdateUser,
  } = useUpdateUser();

  const error = createUserError || errorUpdateUser;
  console.log(error);

  const roles = useOptionsLang(list?.userRoles || []);
  const tags = useTagsToOptionsLang(list?.tags || []);
  const groups = list?.groups || [];

  const { t } = useTranslation('users');
  const back = {
    label: t('user.back'),
    url: '/users',
  };
  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      organisations: [organisation],
      groups: [],
      role: 'Learner',
    },
  });

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        tags: user.tags.map((tag: ITag) => tag._id),
      });
    }
  }, [user]);

  const rolesOptions = useMemo(() => roles?.filter((o:IOptionTranslated) => {
    if (o.value !== 'Admin') {
      return o;
    }
    return null;
  }), [roles]);

  async function onSubmit(values: FieldValues) {
    if (!user?._id) {
      const userCreated = await createUser(values);
      navigate(back.url);
      dispatch({
        type: SET_TOAST,
        payload: {
          message: 'Le membre a été crée',
          type: 'success',
        },
      });
      navigate(`/users/${userCreated.user?._id}`);
    } else {
      await updateUser(values);
      dispatch({
        type: SET_TOAST,
        payload: {
          message: 'Le membre a été modifié',
          type: 'success',
        },
      });
    }
  }

  return (
    <div className={styles.user}>
      <HeaderMobile
        back={back}
       />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
          <div className={styles.actions}>
            <div className={styles.role}>
              <StyledSelect
                name='role'
                control={control}
                options={rolesOptions}
              />
            </div>
            <button
              type="button"
              className={`${styles.submit} ${!isDirty ? styles.unActive : ''}`}
              onClick={handleSubmit(onSubmit)}
            >
              {t('btn.save', { ns: 'global' })}
            </button>
          </div>
        </header>
        <div className={styles.content}>
          <UserForm
            user={user || null}
            control={control}
            errors={errors}
            apiError={error}
            tags={tags}
            groups={groups}
            watch={watch}
          />
        </div>
        <div className={styles.mobile}>
          <button
            type="button"
            className={styles.submit}
            onClick={handleSubmit(onSubmit)}
          >
            {t('btn.save', { ns: 'global' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default User;
