import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from './session.module.scss';
import { Stars } from '../../components/QuizzComponents/Question';
import { ErrorField, InputText } from '../../lib/HooksFormFields';
import { usePostSessionLogin } from '../../hooks/quiz';

export default function Session() {
  const { t } = useTranslation('currentQuiz');
  const [isHover, setIsHover] = useState(true);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();

  const {
    mutateAsync: postLogin,
    error: errorPost,
  } = usePostSessionLogin();

  const errorApi = (errorPost as any)?.response?.data?.error;

  async function onSubmit(data: FieldValues) {
    const response = await postLogin(data);
    console.log(response);
    await navigate(`/session/${response?.session._id}/${response?.user._id}/${response?.session.quiz}`);
  }

  console.log(errorApi);

  return (
    <div className={styles.session}>
      <div className={styles.content}>
        <h1>{t('session.connect')}</h1>
        <div className={styles.field}>
          <InputText
            name='sessionId'
            label={t('session.sessionId')}
            control={control}
            className={'session'}
            rules={{
              required: t('session.errors.field.sessionId'),
            }}
          />
          {errors.sessionId?.message && <ErrorField message={errors.sessionId?.message} />}
        </div>
        <div className={styles.field}>
          <InputText
            name='userId'
            label={t('session.userId')}
            className={'session'}
            control={control}
            rules={{
              required: t('session.errors.field.userId'),
            }}
          />
          {errors.userId?.message && <ErrorField message={errors.userId?.message} />}
        </div>
        {errorApi
          && <p className={styles.error}>
            {errorApi === 'The requested session was not found' && t('session.errors.api.session')}
          </p>
        }
        <button
          className={styles.valid}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={handleSubmit(onSubmit)}
        >
          <Stars isHover={isHover}/>
          {t('session.valid')}
        </button>
      </div>
    </div>
  );
}
