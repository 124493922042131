import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import HeaderMobile from '../../components/HeaderMobile';
import Loader from '../../components/QuizzComponents/Loader';

import QuizModal from '../../components/QuizzComponents/QuizModal';
import { useGetUserQuiz } from '../../hooks/quiz';
import useTagsToOptionsLang, { ITag } from '../../hooks/useTagsToOptionsLang';
import { IQuiz } from '../../types/quiz';
import styles from './tutorQuizList.module.scss';
import TutorQuizListDesktop from '../../components/tutorQuiz/TutorQuizListDesktop';
import { useGetListQuizQuery } from '../../services/api/quiz';
import useOptionsLang from '../../hooks/useOptionsLang';
import TutorQuizListMobile from '../../components/tutorQuiz/TutorQuizListMobile';

// import QuizList from '../../components/QuizzComponents/QuizList';

const TutorQuizList = () => {
  const { t } = useTranslation('quiz');
  const { data: userQuiz = [], isLoading } = useGetUserQuiz();
  const { data: list } = useGetListQuizQuery();
  const [searchParams] = useSearchParams();
  const {
    tag = '',
    status = '',
    search = '',
  } = Object.fromEntries(searchParams);

  const [tags, setTags] = useState<ITag[]>([]);

  const statusOptions = useOptionsLang(list?.status || []);
  const tagsOptions = useTagsToOptionsLang(tags);

  const tutorQuiz = useMemo(() => {
    const filteredQuiz = userQuiz.filter((q: IQuiz) => {
      if (tag && !q.tags.find((ta: ITag) => ta._id === tag)) {
        return null;
      }
      if (status && q.status !== status) {
        return null;
      }
      if (search && !q.name.toLowerCase().includes(search.toLowerCase())) {
        return null;
      }
      return q;
    });
    return filteredQuiz;
  }, [userQuiz, tag, status, search]);

  useEffect(() => {
    if (userQuiz.length > 0) {
      const tagsList : ITag[] = [];
      userQuiz.forEach((q:IQuiz) => {
        q.tags.forEach((tq:ITag) => {
          if (!tagsList.find((ta: ITag) => ta._id === tq?._id)) {
            tagsList.push(tq);
          }
        });
      });
      setTags(tagsList);
    }
  }, [userQuiz]);

  return (
    <div className={styles.quiz}>
      <HeaderMobile title={t('tutorTitle')} />
      <div className={styles.page}>
        {isLoading
          ? <div className={styles.loading}>
            <Loader />
          </div>
          : <>
            <TutorQuizListDesktop
              quiz={tutorQuiz}
              tags={tagsOptions}
              status={statusOptions}
              title={t('tutorTitle')}
            />
            <TutorQuizListMobile
               quiz={tutorQuiz}
               tags={tagsOptions}
               status={statusOptions}
            />
          </>
        }
      </div>
      <QuizModal />
    </div>
  );
};

export default TutorQuizList;
