/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { useRanger, Ranger } from '@tanstack/react-ranger';

// style with styled-component
export const Track = styled('div')`
  height: 35px;
  width: 100%;
  position: relative;
  userselect: none;
  height: 35px;
  background: transparent;
  boxshadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
`;

export const Segment = styled('div')`
  position: absolute;
  color: #4C549E;
  border-top-left-radius: ${(props: { $index: number }) => (props.$index === 0 ? '10px' : '0px')};
  border-bottom-left-radius: ${(props: { $index: number }) => (props.$index === 0 ? '10px' : '0px')};
  border-top-right-radius: ${(props: { $index: number }) => (props.$index === props.$lastIndex ? '10px' : '0px')};
  border-bottom-right-radius: ${(props: { $index: number }) => (props.$index === props.$lastIndex ? '10px' : '0px')};
  border: ${(props: { $index: number, $isLengthTwo: number }) => (props.$entriesLength === 2
    ? (props.$index === 0
      ? '1px solid rgba(76, 84, 158, 0.45)'
      : '1px solid rgba(76, 84, 158, 0.08)'
    )
    : (props.$index === 0
      ? '1px solid rgba(76, 84, 158, 0.45)'
      : props.$index === 1
        ? '1px solid rgba(76, 84, 158, 0.40)'
        : props.$index === 2
          ? '1px solid rgba(76, 84, 158, 0.23)'
          : '1px solid rgba(76, 84, 158, 0.08)'))};
  background: ${(props: { $index: number; $left: number ; $width: number, $entriesLength: number }) => (props.$entriesLength === 2
    ? (props.$index === 0
      ? 'rgba(76, 84, 158, 0.45)'
      : 'rgba(76, 84, 158, 0.08)')
    : (props.$index === 0
      ? 'rgba(76, 84, 158, 0.55)'
      : props.$index === 1
        ? 'rgba(76, 84, 158, 0.40)'
        : props.$index === 2
          ? 'rgba(76, 84, 158, 0.23)'
          : 'rgba(76, 84, 158, 0.08)'))};
  
  
  left: ${(props: { $left: number }) => `${props.$left}%`};
  height: 100%;
  width: ${(props: { $width: number }) => `${props.$width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Handle = styled('button')`
  position: absolute;
  top: -7px;
  left: calc(${(props: { $left: number }) => `${props.$left}%`} - 1px);
  zIndex: isActive ? 1 : 0;
  appearance: none;
  border: 1px solid #EAEAEA;
  outline: none;
  padding: 0;
  &:focus {
    outline:none
  }
  width: 9px;
  height: 49px;
  background: #FFF;
  box-shadow: 2px 2px 2.3px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  }
`;
// end of style with styled-component

function RangerComponent(
  {
    total,
    entries,
    handleChange,
  }:
  {
    total: number,
    entries: any,
    handleChange: any,
  },
) {
  function maxRange() {
    return entries.map((item) => item.value).reduce((a, b) => a + b, 0);
  }

  const rangerRef = React.useRef<HTMLDivElement>(null);

  const entriesWithoutLastOne = entries.slice(0, entries.length - 1);
  const values = entriesWithoutLastOne.map((e) => e.rangeValue);

  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values,
    min: 0,
    max: maxRange(),
    stepSize: 1,
    onChange: (instance: Ranger<HTMLDivElement>) => handleChange(instance.sortedValues),
  }, [entries, total, values]);

  const lastIndex = entries.length - 1;
  const entriesLength = entries.length;

  return (
    <div>
      <Track ref={rangerRef}>
        {rangerInstance.getSteps().map(({ left, width }, i) => (
          <Segment
            key={i}
            $index={i}
            $left={left || 0}
            $width={width || 0}
            $lastIndex={lastIndex}
            $entriesLength={entriesLength}
          >
            {width === 0
              ? ''
              : entries[i].range}
          </Segment>
        ))}
        {rangerInstance
          .handles()
          .map(
            (
              {
                value,
                onKeyDownHandler,
                onMouseDownHandler,
                onTouchStart,
                isActive,
              },
              i,
            ) => (
              <Handle
                key={i}
                onKeyDown={onKeyDownHandler}
                onMouseDown={onMouseDownHandler}
                onTouchStart={onTouchStart}
                role="slider"
                aria-valuemin={rangerInstance.options.min}
                aria-valuemax={rangerInstance.options.max}
                aria-valuenow={value}
                $left={rangerInstance.getPercentageForValue(value) || 0}
                $active={isActive.toString()}
              />
            ),
          )}
      </Track>
      <pre
        style={{
          display: 'inline-block',
          textAlign: 'left',
        }}
      >
        {/* <code>
          {values.toString()}
        </code> */}
      </pre>
    </div>
  );
}

export default RangerComponent;
