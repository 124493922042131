import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import FilterSearchParam from '../../components/FilterSearchParam';
import HeaderMobile from '../../components/HeaderMobile';
import { useGetArticles, useGetArticlesList } from '../../hooks/articles';
import useTagsToOptionsLang, { ITagTranslated } from '../../hooks/useTagsToOptionsLang';
import { IArticle } from '../../types/article';
import styles from './articlesList.module.scss';

const ArcticleCard = ({ article }: { article: IArticle }) => {
  const tags = useTagsToOptionsLang(article?.tags || []);
  return <Link
      to={`/actus/${article._id}`}
      className={styles.card}
    >
    <div className={styles.cover}>
      {article?.cover?.path && <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.cover?.path}`} alt={article.title}/>}
    </div>
    <div className={styles.infos}>
      <div className={styles.tags}>
        {tags.map((t: ITagTranslated) => <p key={`${article?._id}${t.value}`}>{t.label}</p>)}
      </div>
      <p className={styles.creator}>
        {article?.creator?.profile?.firstName} {article?.creator?.profile?.lastName}
      </p>
      <div>
        <h3>{article?.title}</h3>
        <p>{article?.description}</p>
      </div>
    </div>
  </Link>;
};

const ArticlesList = () => {
  const { t } = useTranslation('articles');
  const title = t('title');
  const [searchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);

  const { data: list } = useGetArticlesList();

  const months = list?.months.map((d: string) => ({
    value: d,
    label: new Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' })
      .format(new Date(d)),
  })) || [];

  const { data, isLoading } = useGetArticles({
    ...paramsObject,
  });
  const articles = data || [];

  const tags = useTagsToOptionsLang(list?.tags || []);

  return (
    <div className={styles.user}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <header>
          <h1>{title}</h1>
        </header>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <FilterSearchParam
              label={t('filters.tags')}
              name='tag'
              options={tags || []}
            />
          </div>
          {/* <div className={styles.filter}>
            <FilterSearchParam
              label={t('filters.organisation')}
              name={'organisation'}
              options={list?.organisations || []}
              feminine
            />
          </div> */}
          <div className={styles.filter}>
            <FilterSearchParam
              label={t('filters.region')}
              name={'region'}
              options={list?.regions || []}
              feminine
            />
          </div>
          <div className={styles.filter}>
            <FilterSearchParam
              label={t('filters.date')}
              name={'publicationDate'}
              options={months || []}
              feminine
            />
          </div>
        </div>
        <div className={styles.grid}>
          {!isLoading && articles.map((a: IArticle) => <ArcticleCard
            key={a._id}
            article={a}
          />)}
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
