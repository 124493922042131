import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';

import styles from './plantsGroupModal.module.scss';
import { ErrorField, StyledSelect } from '../../../lib/HooksFormFields';
import { getPlantsListGroupInfos } from '../../../api/plants';
import { useGetPlantsListsLists, useUpdatePlantsListGroup } from '../../../hooks/plantsList';
import Loader from '../../QuizzComponents/Loader';

const PlantsGroupModal = ({
  plantsListId,
  close,
}: {
  plantsListId?: string,
  close : () => void
}) => {
  const { t } = useTranslation('plantsLists');
  const [count, setCount] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: list = {} } = useGetPlantsListsLists();
  const {
    mutateAsync: update,
    isLoading: isPutLoading,
  } = useUpdatePlantsListGroup();
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm({
    defaultValues: {
      type: 'taxonomical_group',
      group: null,
    },
  });
  const type = watch('type');
  const group = watch('group');

  console.log(list);

  const optionsGroup = useMemo(() => {
    let options : any[] = [];
    if (!list.taxonomical_group
      || !list.conditions_of_use
      || !list.morphological_group) return options;
    if (type === 'taxonomical_group') {
      options = list.taxonomical_group.map((d: string) => ({
        value: d,
        label: d,
      }));
    }
    if (type === 'conditions_of_use') {
      options = list.conditions_of_use.map((d: string) => ({
        value: d,
        label: d,
      }));
    }
    if (type === 'morphological_group') {
      options = list.morphological_group.map((d: string) => ({
        value: d,
        label: d,
      }));
    }
    return options;
  }, [list.taxonomical_group, list.conditions_of_use, list.morphological_group, type]);

  const typesOptions = [
    {
      value: 'taxonomical_group',
      label: t('form.group.taxonomical_group'),
    },
    {
      value: 'conditions_of_use',
      label: t('form.group.conditions_of_use'),
    },
    {
      value: 'morphological_group',
      label: t('form.group.morphological_group'),
    },
  ];

  const selected = typesOptions.find((ty) => ty.value === type);

  const getGroupInfos = useCallback(async () => {
    setIsLoading(true);
    const data : any = await getPlantsListGroupInfos({
      _id: plantsListId,
      type,
      group,
    });
    setCount(data?.plantsLength);
    setIsLoading(false);
  }, [type, group, plantsListId]);

  const submit = async (data: FieldValues) => {
    await update({
      _id: plantsListId,
      ...data,
    });
    close();
  };

  useEffect(() => {
    if (group) {
      getGroupInfos();
    }
  }, [group]);

  return (
    <div className={styles.modalPlants}>
      <h3>{t('form.group.title')}</h3>
      <div className={styles.field}>
        <StyledSelect
          name='type'
          label={t('form.group.type')}
          control={control}
          options={typesOptions}
          handleChange={() => setValue('group', null)}
        />
      </div>
      {selected
      && <div className={styles.field}>
        <StyledSelect
          name='group'
          label={selected?.label}
          control={control}
          options={optionsGroup}
          rules={{ required: t('requiredField', { ns: 'global' }) }}
        />
        {errors.group?.message && <ErrorField message={errors.group?.message} />}
      </div>
      }
      <div className={styles.actions}>
        {count > 0 && <p>{count} {t('plants')}</p>}
        <button
          onClick={handleSubmit(submit)}
          className={isPutLoading ? styles.disabled : ''}
        >
           {(isLoading || isPutLoading) && (
              <span className={styles.loader}>
                <Loader small isWhite />
              </span>
           )}
          {t('form.group.add')}
        </button>
      </div>
    </div>
  );
};

export default PlantsGroupModal;
