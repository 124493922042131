import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Form from '../../components/form/FormationForm';
import backIcon from '../../assets/images/back-grey.svg';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './formationForm.module.scss';
import { useCreateFormation, useGetFormationById, useUpdateFormation } from '../../hooks/formations';
import { SET_TOAST } from '../../actions/types';

const FormationForm = () => {
  const { t } = useTranslation('formations');
  const formRef = useRef<any>();
  const navigate = useNavigate();
  const { formationId } = useParams();
  const { data: formation } = useGetFormationById(formationId);

  const dispatch = useDispatch();
  // Create
  const {
    mutateAsync: createFormation,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateFormation();

  const {
    mutateAsync: updateFormation,
    error: errorPut,
  } = useUpdateFormation();

  const errorApi: any = errorPost || errorPut;
  console.log(errorApi);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    if (!formation?._id) {
      const { formation: createdFormation }: any = await createFormation(data);
      if (createdFormation) {
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'La formation a été crée',
            type: 'success',
          },
        });
        navigate(`/formations/detail/${createdFormation?._id}`);
      }
    } else {
      const { formation: updatedFormation }: any = await updateFormation(data);
      if (updatedFormation) {
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'La formation a été modifiée',
            type: 'success',
          },
        });
        navigate(`/formations/detail/${updatedFormation?._id}`);
      }
    }
  };
  const back = {
    label: t('formation.back'),
    url: '/formations',
  };
  return (
    <div className={styles.user}>
      <HeaderMobile back={back} />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
          <div className={styles.actions}>
          <button disabled={isPostLoading} onClick={handleSubmit}
            className={styles.submit}
          >
            {isPostLoading && <div className={styles.dotLoader} />}
            <span>{t('btn.save', { ns: 'global' })}</span>
          </button>
          </div>
        </header>
        <div className={styles.content}>
          <Form ref={formRef} formation={formation} />
        </div>
        <div className={styles.mobile}>
          <button
            type="button"
            className={styles.submit}
            onClick={handleSubmit}
          >
            {t('btn.save', { ns: 'global' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormationForm;
