/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import getUserQuiz from '../../api/quiz';
import postSessionLogin from '../../api/session';

const useGetUserQuiz = () => useQuery({
  queryKey: ['UserQuiz'],
  queryFn: async (): Promise<any | null> => getUserQuiz(),
  keepPreviousData: true,
  refetchInterval: 300000,
});

const usePostSessionLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => postSessionLogin(data),
    onSuccess: async ({ user, token, session }) => {
      localStorage.setItem('token', token);
      queryClient.setQueryData(['UserSession'], { user, session });
      return { user, token, session };
    },
  });
};

export {
  useGetUserQuiz,
  usePostSessionLogin,
};
