import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { IoExtensionPuzzle } from 'react-icons/io5';
import FilterSearchParam from '../../components/FilterSearchParam';

import HeaderMobile from '../../components/HeaderMobile';
import Loader from '../../components/QuizzComponents/Loader';
import QuizList from '../../components/QuizzComponents/QuizList';
import QuizModal from '../../components/QuizzComponents/QuizModal';
import { useGetUserQuiz } from '../../hooks/quiz';
import useTagsToOptionsLang, { ITag } from '../../hooks/useTagsToOptionsLang';
import { IQuiz } from '../../types/quiz';
import styles from './learnerQuizList.module.scss';

// import QuizList from '../../components/QuizzComponents/QuizList';

const LearnerQuizList = ({ isTutor }: { isTutor?: boolean }) => {
  const { t } = useTranslation('quiz');
  const { data: userQuiz = [], isLoading } = useGetUserQuiz();
  const [searchParams] = useSearchParams();
  const {
    tag = '',
  } = Object.fromEntries(searchParams);

  const [tags, setTags] = useState<ITag[]>([]);

  const tagsOptions = useTagsToOptionsLang(tags);

  const quiz = useMemo(() => {
    const filteredQuiz = userQuiz.filter((q: IQuiz) => {
      if (tag && !q.tags.find((ta: ITag) => ta._id === tag)) {
        return null;
      }
      return q;
    });
    return ({
      tutor: filteredQuiz.filter((q: IQuiz) => !q.isTepik),
      tepic: filteredQuiz.filter((q: IQuiz) => q.isTepik),
    });
  }, [userQuiz, tag]);

  const tutorQuiz = useMemo(() => {
    const filteredQuiz = userQuiz.filter((q: IQuiz) => {
      if (tag && !q.tags.find((ta: ITag) => ta._id === tag)) {
        return null;
      }
      return q;
    });
    return filteredQuiz;
  }, [userQuiz, tag]);

  useEffect(() => {
    if (userQuiz.length > 0) {
      const tagsList : ITag[] = [];
      userQuiz.forEach((q:IQuiz) => {
        q.tags.forEach((tq:ITag) => {
          if (!tagsList.find((ta: ITag) => ta._id === tq?._id)) {
            tagsList.push(tq);
          }
        });
      });
      setTags(tagsList);
    }
  }, [userQuiz]);

  return (
    <div className={styles.quiz}>
      <HeaderMobile title={isTutor ? t('tutorTitle') : t('title')} />
      <div className={styles.page}>
        <div className={styles.header}>
          <h1>{isTutor ? t('tutorTitle') : t('title')}</h1>
          {isTutor
            && <Link to='/tutor-quiz/create' className={styles.create}>
              <IoExtensionPuzzle />{t('create')}
            </Link>
          }
        </div>
        {isLoading
          ? <div className={styles.loading}>
            <Loader />
          </div>
          : <>
            <div className={styles.content}>
            <div className={styles.filters}>
              <div className={styles.filter}>
                <FilterSearchParam
                  label={t('filters.tags')}
                  name='tag'
                  options={tagsOptions}
                />
              </div>
            </div>
            {!isTutor
              ? <>
              {quiz?.tutor?.length > 0
                && <section>
                  <h2>{t('tutorQuiz')}</h2>
                  {quiz?.tutor && <QuizList list={quiz?.tutor} />}
                </section>
              }
              {quiz?.tepic?.length > 0
                && <section>
                  <h2>{t('tepikQuiz')}</h2>
                  {quiz?.tepic && <QuizList list={quiz?.tepic} />}
                </section>
              }
              </>
              : <section>
                  {tutorQuiz && <QuizList list={tutorQuiz} isTutor />}
              </section>
            }
          </div>
          </>
        }
      </div>
      <QuizModal />
    </div>
  );
};

export default LearnerQuizList;
