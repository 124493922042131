import React, { useCallback, useMemo } from 'react';
import { Control, useForm, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { IoAdd } from 'react-icons/io5';
import {
  StyledSelect,
  StyledCreatableSelect,
} from '../../lib/HooksFormFields';
import Tag from '../Tag';
import { IOption } from '../../types/options';
import styles from './select-multiple.module.scss';

interface SelectMultipleProps {
  label?: string,
  name: string;
  control: Control<any>;
  options: IOption[],
  isIcon?: boolean,
  placeholder?: string,
  isCreatable?: boolean,
}

const SelectMultiple = ({
  label,
  name,
  control,
  options,
  // isIcon = false,
  placeholder = '',
  isCreatable,
}: SelectMultipleProps) => {
  const { t } = useTranslation('global');
  const {
    control: selectControl, setValue, reset,
  } = useForm();

  const { field: { onChange, value: selections } } = useController({
    control,
    name,
  });

  const addItem = (option: string) => {
    if (option) {
      const arrayValues = selections || [];
      onChange([...arrayValues, option]);
      reset();
    }
    setValue(name, null);
  };

  const deleteItem = (string: string) => {
    const arrayValues = (selections || []).filter((str: string) => str !== string);
    onChange(arrayValues);
  };

  const getText = useCallback((str: string) => (
    (options || []).find((opt: IOption) => opt.value === str)?.label
  ), [options]);

  const getColor = useCallback((str: string) => (
    (options || []).find((opt: IOption) => opt.value === str)?.color
  ), [options]);

  const filteredOptions = useMemo(() => (
    options.filter((opt: IOption) => (!selections || !selections.includes(opt.value)))
  ), [options, selections]);

  return (
    <div className={styles.selectMultiple}>
      <h4>{label}</h4>
      <div className={`${styles.containerField} ${styles.type}`}>
        {isCreatable
          ? <StyledCreatableSelect
          name={'select'}
          control={selectControl}
          options={filteredOptions}
          placeholder={placeholder}
          formatCreateLabel={t('add')}
          handleChange={(option) => addItem(option?.value)}
          isSearchable
          isClearable
        />
          : <StyledSelect
          name={'select'}
          control={selectControl}
          options={filteredOptions}
          placeholder={placeholder}
          handleChange={(option) => addItem(option?.value)}
          isClearable
        />
        }
        {/* <button className={isIcon ? styles.icon : ''}
          onClick={addItem}>{isIcon ? <IoAdd /> : t('add')}
        </button> */}
      </div>
      {(selections || []).length > 0 && (
        <div className={styles.tags}>
          {selections.map((str: string, index: number) => (
            <Tag
              key={`${name}-${index}`}
              text={getText(str) || str || ''}
              color={getColor(str)}
              handleDelete={() => deleteItem(str)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectMultiple;
