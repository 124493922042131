import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';
import styles from './sessionWait.module.scss';

export default function SessionWait() {
  const { t } = useTranslation('currentQuiz');
  const [startQuiz, setStartQuiz] = useState(false);
  const { sessionId, userId, quizId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/exam-sessions/listen/start/${userId}/${sessionId}`);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('event', data, quizId);
      if (data.message === 'sessionStart') {
        setStartQuiz(true);
      }
      if (data.message === 'sessionCancel' || data.message === 'sessionClose') {
        navigate('/session');
      }
    };

    eventSource.onerror = (err) => {
      console.error('SSE error:', err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div className={styles.session}>
      <div className={styles.content}>
        {!startQuiz && <h1>{t('session.wait')}</h1>}
        {startQuiz
          && <button
            className={styles.valid}
            onClick={() => navigate(`/session/quiz/${quizId}?session=${sessionId}&user=${userId}`)}
          >
            {t('session.start')}
          </button>
        }
      </div>
    </div>
  );
}
