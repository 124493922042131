import { useWindowSize } from '@uidotdev/usehooks';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { GoArrowLeft, GoHomeFill } from 'react-icons/go';
import { IoMdSettings } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { getCurrentQuizAction, startQuizzAction } from '../../actions/userQuiz';
import Loader from '../../components/QuizzComponents/Loader';
import Question from '../../components/QuizzComponents/Question';
import Timer from '../../components/QuizzComponents/Timer';
import endImg from './images/end.svg';
import endMobileImg from './images/endMobile.svg';

import { Answer, QuestionType } from '../../types/userQuiz';
import styles from './currentQuiz.module.scss';
import { IQuestion } from '../../types/questions';
import { SettingsMobile } from '../../components/QuizzComponents/Settings';
import { SET_USER_QUIZ_SETTINGS, SET_ZOOMED_IMAGE } from '../../actions/types';
import ZoomDesktop from '../../components/QuizzComponents/ZoomDesktop';

const ProgressItem = ({
  index,
  currentIndex,
  awnser,
}:{
  index: number,
  currentIndex: number,
  awnser: Answer,
}) => (
    <div className={`${styles.progressItem} ${awnser && !awnser?.isCorrect ? styles['not-valid'] : ''} ${awnser && awnser?.isCorrect ? styles.valid : ''} ${index < currentIndex ? styles.prev : ''} ${index === currentIndex ? styles.active : ''}`}>

    </div>
);

const CurrentQuiz = () => {
  const params = useParams();
  const quizId = params?.id;
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');
  const navigate = useNavigate();
  const imagesRef = useRef(null);
  const { current, settings } = useSelector((s: any) => s.userQuizReducer);
  const { user: userAuth } = useSelector((d: any) => d.authReducer);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSettings, setIsSetting] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    formation = '',
    session = '',
    user = '',
  } = Object.fromEntries(searchParams);
  const { width } = useWindowSize();
  const [isEnd, setIsEnd] = useState(false);
  const { quiz } = current;
  const awnsers = current?.answers && current.answers;
  const questions = quiz?.questions?.length > 0 ? [...quiz.questions] : [];

  const correctsAwnsers = awnsers?.entries?.filter((e: Answer) => e.isCorrect)?.length || 0;

  function handleChangeSettings(obj: any) {
    dispatch({
      type: SET_USER_QUIZ_SETTINGS,
      payload: {
        ...settings,
        ...obj,
      },
    });
  }

  function handleQuestionIndex(index: number) {
    let newIndex = index;
    if (index < 0) {
      newIndex = 0;
    }
    if (index === questions.length + 1) {
      newIndex = questions.length;
    }
    setCurrentIndex(newIndex);
  }

  const getStyle = () => {
    if (width && questions?.length) {
      const style = {
        width: `${(questions.length + 1) * width}px`,
        left: `-${currentIndex * width}px`,
      };
      return style;
    }
    return {};
  };

  const getPrevClass = (i :number) => {
    if (currentIndex + 1 === i) {
      return styles.next;
    }
    if (currentIndex + -1 === i) {
      return styles.prev;
    }
    return '';
  };

  const handleClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'IMG') {
      const { src } = (target as HTMLImageElement);
      dispatch({
        type: SET_ZOOMED_IMAGE,
        payload: src,
      });
    }
  };

  const timerEnd = () => {
    console.log('end', quiz);
    if (quiz?.limitedTime && quiz?.limitedTimeStop) {
      setIsEnd(true);
    }
  };

  const getDuration = useCallback(() => {
    let duration = quiz?.duration || 0;
    if (userAuth?.isSpecifik) {
      duration *= 1.3;
    }
    return duration;
  }, [userAuth, quiz.duration]);

  useEffect(() => {
    if (quizId) {
      getCurrentQuizAction(dispatch, quizId);
      startQuizzAction(dispatch, quizId, formation, session);
    }
  }, [quizId]);

  useEffect(() => {
    let eventSource : any = null;
    if (user && session) {
      eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/exam-sessions/listen/start/${user}/${session}`);

      eventSource.onmessage = (event:any) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.message === 'sessionCancel' || data.message === 'sessionClose') {
          navigate('/session');
        }
      };

      eventSource.onerror = (err:any) => {
        console.error('SSE error:', err);
        eventSource.close();
      };
    }
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);

  useEffect(() => {
    const quizElement : any = imagesRef.current;

    if (quizElement) {
      if (settings?.isZoom) {
        quizElement?.addEventListener('click', handleClick);
      } else {
        quizElement?.removeEventListener('click', handleClick);
      }
    }
    return () => {
      if (quizElement) {
        quizElement?.removeEventListener('click', handleClick);
      }
    };
  }, [settings?.isZoom]);

  return (
    <div ref={imagesRef} className={`${styles.container} ${settings?.isZoom ? styles.zoom : ''}`}>
      {!settings?.isZoom
        && <>
          {!session
            && <Link to={'/'} className={styles.back}>
              <span><GoArrowLeft /></span>
              <span><GoHomeFill /></span>
            </Link>
            }
            <header>
            <div className={styles.infos}>
              <div className={styles.name}>
                <h2>{quiz?.name}</h2>
              </div>
              <div className={styles.count}>
                <p>Q {currentIndex < questions.length
                  ? currentIndex + 1 : questions.length}  / {questions?.length}</p>
                {quiz?.duration && <Timer
                  duration={getDuration()}
                  callback={() => timerEnd()}
                />}
              </div>
            </div>
            <div className={styles.progress}>
              {quiz?.questions?.map((q:IQuestion, i: number) => <ProgressItem
                key={`progress-${i}`}
                index={i}
                awnser={quiz?.showCorrections && awnsers?.entries && awnsers?.entries[i]}
                currentIndex={currentIndex}
              />)}
            </div>
          </header>
        </>
      }
      {settings.isZoom
        && <header>
          <h3>{t('current.zoom.title')}</h3>
        </header>
      }
      <button
        type='button'
        id="settings-toggle"
        className={`${styles['settings-toggle']} ${isSettings ? styles.active : ''}`}
        onClick={() => setIsSetting(!isSettings)}
      >
        <IoMdSettings />
      </button>
      {((currentIndex === questions.length && awnsers && questions) || isEnd)
        && <div className={styles.end}>
          <img src={endImg} className={styles.desktop}/>
          <img src={endMobileImg} className={styles.mobile}/>
          <h1>Bravo !</h1>
          <p>Tu as réussi à finir le test</p>

          {!session
            && <p className={styles.score}>
              Tu as obtenu<br/>{' '}un score de {correctsAwnsers} / {questions?.length}
            </p>
          }
        </div>
      }
      {awnsers && questions && width && currentIndex < questions.length && !isEnd && <div
        className={styles.content}
      >
        <div
          className={styles.list}
          style={getStyle()}>
          {questions.map((q : QuestionType, index: number) => (<div
            key={q?._id}
            className={`${styles.question} ${getPrevClass(index)}`}
          >
              {index === currentIndex ? <Question
                question={q}
                index={index}
                handleQuestionIndex={handleQuestionIndex}
              />
                : <div className={styles['empty-question']}/>}
          </div>))}
        </div>
      </div>
      }
      {!awnsers && !questions && width && <div className={styles.loader}>
          <Loader isWhite={!!(width && width > 900)}/>
        </div>
      }
      {currentIndex < questions.length
        && <button
          type='button'
          className={`${styles['zoom-mobile-toggle']}`}
          onClick={() => handleChangeSettings({ isZoomMobile: true })}
        >
          <IoSearch />
        </button>
      }
      <ZoomDesktop />
      <SettingsMobile
        isOpen={isSettings}
      />
    </div>
  );
};

export default CurrentQuiz;
