import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { IoAdd } from 'react-icons/io5';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFormationById, useGetFormationsList, useUpdateFormation } from '../../../hooks/formations';
import useTagsToOptionsLang, { ITag } from '../../../hooks/useTagsToOptionsLang';
import { FormationQuiz } from '../../../types/formations';
import { IQuiz } from '../../../types/quiz';

import FilterSearchParam from '../../FilterSearchParam';
import Tags from '../../Tags';
import styles from './FormationsQuizModal.module.scss';

export default function FormationsQuizModal({
  close,
}:{
  close: () => void,
}) {
  const { t } = useTranslation('formations');
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const { id } = useParams();
  const {
    data: formation,
  } = useGetFormationById(id);

  const { data: list } = useGetFormationsList();
  const {
    mutateAsync: updateFormation,
  } = useUpdateFormation();
  const allQuiz = list?.quiz || [];
  const tags = useTagsToOptionsLang(list?.tags || []);
  const [selectedQuiz, setSelectedQuiz] = useState<string[]>([]);

  const tag = searchParams.get('quiz.tag');
  const quiz = formation?.quiz?.map((l: FormationQuiz) => l.quiz._id) || [];

  const quizFiltered = useMemo(() => allQuiz.filter((u: IQuiz) => {
    if (quiz.find((l: string) => l === u._id)) {
      return null;
    }
    if (search && !u.name.toLowerCase().includes(search.toLowerCase())) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    return u;
  }), [allQuiz, tag, search]);

  function handleSelectQuiz(quizId: string) {
    let quizSelected : string[] = [...selectedQuiz];
    if (quizSelected.find((u: string) => u === quizId)) {
      quizSelected = quizSelected.filter((u: string) => u !== quizId);
    } else {
      quizSelected.push(quizId);
    }
    setSelectedQuiz(quizSelected);
  }

  async function handleAddQuiz() {
    const currentQuiz = formation?.quiz || [];
    const quizUpdated : any = [
      ...currentQuiz.map((q: any) => ({ quiz: q.quiz._id, successCondition: q.successCondition })),
      ...selectedQuiz.map((s: string) => ({ quiz: s, successCondition: 0 })),
    ];
    await updateFormation({
      _id: id,
      quiz: quizUpdated,
    });
    await close();
    searchParams.delete('quiz.tag');
    setSearchParams(searchParams);
  }

  return (
    <div className={styles.container}>
      <header>
        <h2>Ajouter des quiz</h2>
      </header>
      <div className={styles.filters}>
        <div className={styles.search}>
          <input
            value={search}
            placeholder={t('filters.search', { ns: 'formations' })}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <FilterSearchParam
          label={t('filters.tags', { ns: 'formations' })}
          name='quiz.tag'
          options={tags || []}
        />
      </div>
      <div className={`${styles.row} ${styles.label}`}>
        <div className={styles.col}>
        </div>
        <div className={styles.col}>
          <p>Nom du quiz</p>
        </div>
        <div className={styles.col}>
          <p>Tags</p>
        </div>
      </div>
      <div className={styles.list}>
        {quizFiltered.map((q: IQuiz) => (
          <div key={q._id} className={styles.row}>
            <div className={styles.col}>
              <div className={styles.check}
                onClick={() => handleSelectQuiz(q._id)}
              >
                {selectedQuiz.find((s: string) => s === q._id) && <FaCheck />}
              </div>
            </div>
            <div className={styles.col}>
              <p>{q.name}</p>
            </div>
            <div className={styles.col}>
              <Tags tags={q.tags}/>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action}>
        <div
          onClick={() => handleAddQuiz()}
        >
          <button
            type="button"
            onClick={() => handleAddQuiz()}
            className={`${styles.btn} ${styles.add}`}
          >
            <IoAdd/>{t('formation.quiz.modal.add', { ns: 'formations' })}
          </button>
        </div>
      </div>
    </div>
  );
}
