/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PairOption } from '../../../types/userQuiz';
import { getRandomRotation, getTepicPicture } from '../../../utils';
import styles from './AssociatePicturesOptions.module.scss';

const PairItem = ({
  item,
  isDragging = false,
  isDraggedItem = false,
  response,
}:{
  item : any,
  isDragging?: boolean,
  isDraggedItem?: boolean,
  response?: PairOption
}) => {
  const picture = item?.pictures?.length > 0 && item.pictures[0];
  if (response || isDragging) {
    return (<div className={`${styles.option} ${styles.empty} ${item?.pictures?.length > 0 ? styles.pictures : ''}`}
      style={{ transform: `rotate(${item.rotation}deg)` }}
    >
    </div>);
  }
  return (
    <div className={`${styles.option} ${isDraggedItem ? styles.isDragging : ''}`}
      style={isDraggedItem ? {} : { transform: `rotate(${item.rotation}deg)` }}
    >
      {picture && <img
           key={picture.file_name}
           src={getTepicPicture(picture)}
           alt={picture.file_name}/>}
    </div>
  );
};

const ResponseItem = ({
  item,
  isDraggingOver = false,
  isDragging = false,
  handleResponseClick,
}:{
  item : any,
  response?: PairOption,
  isDraggingOver?: boolean,
  isDragging?: boolean,
  handleResponseClick: (v: string | null) => void
}) => {
  const picture = item?.value?.pictures?.length > 0 && item.value.pictures[0];
  return (
  <div
    className={`${styles.option} ${!picture ? styles.empty : ''} ${isDragging ? styles.dragging : ''} ${isDraggingOver ? styles.hover : ''}`}
    onClick={() => handleResponseClick(item?.value?._id)}
  >

    {picture && <img
      key={picture.file_name}
      src={getTepicPicture(picture)}
      alt={picture.file_name}/>}
  </div>
  );
};

const AssociatePicturesOptions = ({
  pairs,
  handleChange,
  disabled = false,
  prevAnswer,
  correction,
  showResponse,
}:{
  pairs: any,
  answerType?: string,
  questionType?: string,
  disabled?: boolean,
  prevAnswer: any,
  correction: string[],
  showResponse?: boolean,
  handleChange: (value: any) => void
}) => {
  const [listA, setListA] = useState<any>([]);
  const [listB, setListB] = useState<any>([]);
  const { settings } = useSelector((s: any) => s.userQuizReducer);
  const [draggedItem, setDraggedItem] = useState<null | string>();
  const { t } = useTranslation('quiz');

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    const style : any = {
      ...draggableStyle,
      transition: null,
      transform: isDragging ? draggableStyle.transform : null,
    };
    return style;
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (settings.isZoom) {
      return;
    }
    setDraggedItem(null);
    if (!destination) {
      return;
    }
    if (source.droppableId === 'listA') {
      const list = [...listB];
      const index = destination.droppableId.split('.');
      const index0 = parseFloat(index[0]);
      const index1 = parseFloat(index[1]);
      const dropped = listA[source.index];
      list[index0].dropzone[index1].value = { ...dropped };
      const { dropzone } = list[index0];
      const filtered = dropzone.filter((d: any) => d.value).map((d: any) => d.value?._id);
      let value = '';
      if (filtered.length === 1) {
        value = `${filtered[0]}`;
      }
      if (filtered.length > 1) {
        value = `${filtered[0]}.${filtered[1]}`;
      }
      list[index0].value = value;
      const selected = list.filter((l) => l?.value.includes('.')).map((l) => l.value);
      handleChange([...selected]);
      setListB(list);
    }
  };

  const handleDragStart = (e: any) => {
    if (settings.isZoom) {
      return;
    }
    setDraggedItem(e.draggableId);
  };

  async function handleResponseClick(id: string, picture: string | null) {
    if (!picture && !draggedItem) {
      return;
    }
    if (settings.isZoom) {
      return;
    }
    const dropped = listA.find((d: any) => d._id === draggedItem) || null;
    const list = [...listB];
    const index = id.split('.');
    const index0 = parseFloat(index[0]);
    const index1 = parseFloat(index[1]);
    list[index0].dropzone[index1].value = dropped;
    const { dropzone } = list[index0];
    const filtered = dropzone.filter((d: any) => d.value).map((d: any) => d.value?._id);
    let value = '';
    if (filtered.length === 1) {
      value = `${filtered[0]}`;
    }
    if (filtered.length > 1) {
      value = `${filtered[0]}.${filtered[1]}`;
    }
    list[index0].value = value;
    const selected = list.filter((l) => l?.value.includes('.')).map((l) => l.value);
    handleChange([...selected]);
    setListB(list);
    setDraggedItem(null);
  }

  const getCorrectionStyle = (index: number) => {
    if (correction?.length
      && showResponse
      && correction?.length === prevAnswer?.selectedPairs?.length) {
      const pair = prevAnswer?.correctAnswers[index].split('.');
      const isCorrect = correction.find((c:string) => c === `${pair[0]}.${pair[1]}` || c === `${pair[1]}.${pair[0]}`);
      return isCorrect ? styles.correct : styles.wrong;
    }
    if (correction?.length && correction?.length === prevAnswer?.selectedPairs?.length) {
      const pair = prevAnswer.selectedPairs[index].split('.');
      const isCorrect = correction.find((c:string) => c === `${pair[0]}.${pair[1]}` || c === `${pair[1]}.${pair[0]}`);
      return isCorrect ? styles.correct : styles.wrong;
    }
    return '';
  };

  useEffect(() => {
    if (pairs) {
      const initA = pairs.map((p :any) => ({
        ...p,
        rotation: getRandomRotation(),
      }));
      setListA(initA);
      const plants = pairs.filter((p: PairOption) => p.group === '1');
      const initB = plants.map((p: any, i: number) => {
        const name = `${t('current.plant')} ${i + 1}`;
        const value = prevAnswer?.selectedPairs?.length > 0 ? prevAnswer?.selectedPairs[i] : '';
        const ids = prevAnswer?.selectedPairs?.length > 0 ? prevAnswer?.selectedPairs[i].split('.') : null;
        const dropzone = [
          {
            id: `${i}.0`,
            value: ids?.length > 0 ? pairs.find((p: any) => p._id === ids[0]) : null,
          },
          {
            id: `${i}.1`,
            value: ids?.length > 0 ? pairs.find((p: any) => p._id === ids[1]) : null,
          },
        ];
        return ({
          name,
          value,
          dropzone,
        });
      });
      setListB(initB);
    }
  }, []);

  useEffect(() => {
    if (showResponse && correction) {
      const response = [...listB];
      response.forEach((r, i) => {
        const correct = listA.filter((a :any) => correction[i].includes(a._id));
        correct.forEach((c: any, ci: number) => {
          response[i].dropzone[ci].value = c;
        });
      });
      setListB(response);
    }
  }, [showResponse, correction]);

  console.log(correction, showResponse);

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}

    >
      <Droppable droppableId="listA" isDropDisabled={true}>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            className={styles.list}
          >
            {listA.map((item :any, index: number) => (
              <Draggable
                key={item._id}
                draggableId={item._id}
                index={index}
                isDragDisabled={listB.find((r: any) => r.value.includes(`${item._id}`))}>
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => !settings.isZoom && setDraggedItem(item._id)}
                      className={`${styles.item} ${listB.find((r: any) => r.value.includes(`${item._id}`)) ? styles.disabled : ''}`}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <PairItem
                      item={item}
                      isDraggedItem={draggedItem === item._id}
                      response={listB.find((r: any) => r.value.includes(`${item._id}`))} />
                      {listB.find((r: any) => r.value.includes(`${item._id}`))?.id}
                    </div>
                    {snapshot.isDragging && (
                      <div className={styles.item} >
                        <PairItem
                          item={item}
                          isDragging={snapshot.isDragging}
                          response={listB.find((r: any) => r.value.includes(`${item._id}`))} />
                      </div>
                    )}

                  </>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder && null}

          </div>
        )}
      </Droppable>
      <div
        className={styles.responses}
        >
        {listB.map((item: any, index: number) => (
          <div key={item.name} className={`${styles.response} ${getCorrectionStyle(index)}`}>
            <p>{item.name}</p>
            <div className={styles.drop}>
              {item.dropzone.map((item: any) => (
                <Droppable key={item.id} droppableId={item.id}
                >
                  {(provided, snapshot) => (
                    <>
                    <div
                      ref={provided.innerRef}
                      style={{
                        transform: '',
                      }}
                    >
                      <ResponseItem
                        item={item}
                        isDraggingOver={snapshot.isDraggingOver}
                        isDragging={!!draggedItem}
                        handleResponseClick={
                          (picture :string | null) => handleResponseClick(item.id, picture)
                        }
                      />
                    </div>
                    {provided.placeholder && null}
                  </>
                  )}
                </ Droppable>
              ))}
              </div>
          </div>
        ))}
      </div>
    </DragDropContext>
    </div>
  );
};

export default AssociatePicturesOptions;
