import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import HeaderMobile from '../../components/HeaderMobile';
import styles from './plantsLists.module.scss';

// import UserListMobile from '../../components/users/userListMobile';
// import UserListDesktop from '../../components/users/userListDesktop';

import { IFormation } from '../../types/formations';
import { useGetPlantsLists } from '../../hooks/plantsList';
import PlantsListsMobile from '../../components/plantsLists/PlantsListsMobile';
// import PlantsListsDesktop from '../../components/plantsLists/plantsListsDesktop';
import useTagsToOptionsLang from '../../hooks/useTagsToOptionsLang';
import { useGetOrganisation } from '../../hooks/organisations';
import PlantsListsDesktop from '../../components/plantsLists/plantsListsDesktop';

const PlantsLists = () => {
  const { t } = useTranslation('plantsLists');
  const [searchParams] = useSearchParams();
  const {
    search = '', tag = '', sortKey = '', order = 'desc',
  } = Object.fromEntries(searchParams);

  const organisationId = localStorage.getItem('organisation');
  const { data: organisation } = useGetOrganisation(organisationId || '');

  // const {
  //   data: list = {},
  // } = useGetUsersList(organisationId ? { organisations: [organisationId] } : {});

  const {
    data: plantsLists = [],
  } = useGetPlantsLists({});

  const tags = useTagsToOptionsLang(organisation?.tags || []);

  const filteredPlantsLists = useMemo(() => {
    let plants = plantsLists || [];
    if (search) {
      plants = plants.filter((f:IFormation) => {
        let isIn = false;
        if (f.name.toLowerCase().includes(search.toLowerCase())) {
          isIn = true;
        }
        return isIn ? f : null;
      });
    }
    if (tag) {
      plants = plants.filter((u:IFormation) => {
        let isIn = false;
        if (u.tags?.find((ta: any) => ta._id === tag)) {
          isIn = true;
        }
        return isIn ? u : null;
      });
    }
    if (sortKey) {
      console.log(sortKey, order);
      if (sortKey === 'name') {
        plants = plants.sort(
          (a: IFormation, b: IFormation) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          },
        );
      }
      if (order === 'asc') {
        plants = plants.reverse();
      }
    }
    return plants;
  }, [plantsLists, search, tag, order, sortKey]);

  const title = `${t('title')}`;

  return (
    <div className={styles.formations}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <PlantsListsMobile
          plantsLists={filteredPlantsLists}
          tags={tags}
        />
         <PlantsListsDesktop
          title={title}
          plantsLists={filteredPlantsLists}
          tags={tags}
        />
      </div>
    </div>
  );
};

export default PlantsLists;
