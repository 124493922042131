import React, { useState } from 'react';
import { GoKebabHorizontal } from 'react-icons/go';
import { IoIosAdd } from 'react-icons/io';
import { IoChevronUp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './formationsListDesktop.module.scss';
import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IFormation } from '../../../types/formations';
import { useDeleteFormation, useDuplicateFormation } from '../../../hooks/formations';
import FormationCard from '../FormationCard';

const Dropdown = ({ id }:{ id: string }) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const {
    mutateAsync: duplicateFormation,
  } = useDuplicateFormation();

  const { mutateAsync: deleteFormation } = useDeleteFormation();

  function handleEdit(e :any) {
    e.stopPropagation();
    navigate(`/formations/${id}`);
  }
  async function handleDuplicate(e :any) {
    e.stopPropagation();
    await duplicateFormation(id);
    setIsOpen(!isOpen);
  }
  function handleIsOpen(e :any) {
    e.stopPropagation();
    setIsOpen(!isOpen);
  }
  async function handleDelete(e :any) {
    e.stopPropagation();
    await deleteFormation(id);
    setIsOpen(!isOpen);
  }

  return (<div className={styles.dropdown}>
    <div
      className={styles.icon}
      onClick={(e) => handleIsOpen(e)}
    >
      <GoKebabHorizontal />
    </div>
    <div className={`${styles['dropdown-content']} ${isOpen ? styles.open : ''}`}>
      <div className={styles.content} onMouseLeave={() => setIsOpen(false)}>
        <div onClick={(e) => handleEdit(e)} className={styles.item}>
          <p>{t('btn.edit')}</p>
        </div>
        <div onClick={(e) => handleDuplicate(e)} className={styles.item}>
          <p>{t('btn.duplicate')}</p>
        </div>
        <div onClick={(e) => handleDelete(e)} className={styles.item}>
          <p>{t('btn.delete')}</p>
        </div>
      </div>
    </div>
  </div>);
};

const Row = ({ formation }: { formation: IFormation }) => {
  const { t } = useTranslation('formations');
  const tags = useTagsToOptionsLang(formation?.tags || []);
  const { user } = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const tag = tags.length > 0 ? tags[0] : null;

  const onRowClick = (e: any, id: string) => {
    e.preventDefault();
    const url = user.role === 'Learner'
      ? `/formations/${id}`
      : `/formations/detail/${id}`;
    navigate(url);
  };

  return (<div
      onClick={(e) => onRowClick(e, formation._id)}
      className={styles.row}
    >
    <div className={styles.col}>
      <p>{formation.name}</p>
    </div>
    <div className={styles.col}>
      <p>{formation.quiz.length}</p>
    </div>
    <div className={styles.col}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
    <div className={`${styles.col} ${styles.status}`}>
      {user.role !== 'Learner'
      && <>
        <div className={styles.state}>
          <span style={{
            backgroundColor: formation.isClose ? '#00875E' : '#00875E',
          }}/>
          <p>{formation.isClose ? t('formation.close') : t('formation.open')}</p>
        </div>
        <Dropdown id={formation._id} />
      </>
      }
    </div>
  </div>);
};

const FormationsListDesktop = ({
  formations,
  tags,
  title,
}: {
  formations: IFormation[],
  tags: any,
  title: string,
}) => {
  const { t } = useTranslation('formations');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((d: any) => d.authReducer);

  const types = [
    {
      label: t('tepik'),
      value: 'true',
    },
    {
      label: t('tutor'),
      value: 'false',
    },
  ];

  const { search = '', order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleCreateFormation = async () => {
    navigate('/formations/create');
  };

  const labels = [
    {
      label: t('formation.label.name'),
      sortKey: 'name',
    },
    {
      label: t('formation.label.count'),
    },
    {
      label: t('formation.label.tags'),
    },
    {
      label: user.role !== 'Learner' ? t('formation.label.state') : '',
    },
  ];

  return (
    <div className={styles.desktop}>
    <div className={styles.header}>
      <h1>{title}</h1>
      <div className={styles.actions}>
        <div className={styles.search}>
          <InputSearch
            defaultValue={search}
            handleSearch={handleSearch}
            isToggle={true}
          />
        </div>
        {user.role !== 'Learner'
          ? <div
            onClick={() => handleCreateFormation()}
            className={`${styles.btn} ${styles.add}`}
          >
            <IoIosAdd />{t('formation.new')}
          </div>
          : <span></span>
        }
      </div>
    </div>
    {formations?.length > 0
      && <>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <FilterSearchParam
            label={t('filters.tags')}
            name='tag'
            options={tags || []}
          />
        </div>
        {user?.role === 'Learner'
          && <div className={styles.filter}>
            <FilterSearchParam
              label={t('filters.type')}
              name='isTepik'
              options={types || []}
            />
          </div>
        }
      </div>
      {user?.role !== 'Learner'
        ? <div className={styles.content}>
            <div className={`${styles.row} ${styles.label}`}>
              {labels.map((l: any) => <div key={l.label}
                className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
                onClick={() => handleSorting(l?.sortKey)}
              >
                {l?.sortKey === sortKey && <div className={styles.icon}>
                  <IoChevronUp />
                </div>
                }
                <p>{l.label}</p>
              </div>)}
            </div>
            <div className={`${styles.list} ${user?.role === 'Learner' ? styles.learner : ''}`}
            >
              {formations.map((f: IFormation) => (<Row
                key={f._id}
                formation={f}
              />))}
            </div>
          </div>
        : <div className={styles.grid}>
          {formations.map((f: IFormation) => (<FormationCard
            key={f._id}
            formation={f}
          />))}
        </div>
      }
      </>
    }
  </div>
  );
};
export default FormationsListDesktop;
