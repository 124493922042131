import { API_URL } from '../actions';

export function getTepicPicture(picture: any) {
  const token = localStorage.getItem('token');
  return `${API_URL}/quiz/pictures/${picture?.directory?.replace('../', '')}/${picture.file_name}?token=${token?.replace('JWT ', '')}`;
}

export function getApiPicture(name: string) {
  const token = localStorage.getItem('token');
  return `${API_URL}/files/public/${name}?token=${token?.replace('JWT ', '')}`;
}

const get = (obj = {}, path: string) => {
  const properties: any[] = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const equals = (a: any, b: any): any => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date) { return a.getTime() === b.getTime(); }

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) { return a === b; }

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};

export {
  get,
  equals,
};

export function hexToRGBA(hex: string, alpha: number = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

export const removeHTMLTags = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};

export function getRandomRotation() {
  return Math.floor(Math.random() * 41) - 20; // Generates a number between -30 and 30
}

export function setVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
