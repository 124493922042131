/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  Control, UseFormGetValues, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { Tooltip } from 'react-tooltip';
import { useGetListQuizQuery } from '../../../services/api/quiz';
import {
  InputText,
  ListTags,
  Textarea,
  InputNumber,
  StyledSelect,
  ErrorField,
} from '../../../lib/HooksFormFields';
// import Modal from '../../../lib/Modal';
// import SelectPlants from './SelectPlants';
import QuestionsDistribution from './QuestionsDistribution';
import styles from './quiz-form.module.scss';
// import RecognitionAdvancedSettings from './RecognitionAdvancedSettings';
import SelectMultiple from '../../SelectMultiple';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { useGetPlantsLists } from '../../../hooks/plantsList';
import AdvancedDistribution from './AdvancedDistribution';
// import difficulitiesColors from '../../../constants';

// type PlantProps = {
//   value: string
//   label: string | null
// };

type InputsProps = {
  [key: string]: {
    value: number;
    // label: string;
    // range: string;
  }
};

const QuizForm = ({
  control,
  getValues,
  setValue,
  watch,
  createList,
} : {
  control: Control<any>,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  watch: UseFormWatch<any>,
  createList: () => void,
}) => {
  const { t } = useTranslation(['createQuiz', 'global']);
  const { data: list = {} } = useGetListQuizQuery();
  const { data: plantsList = {} } = useGetPlantsLists({});
  const [isSelectListPLant, setSelectListPlants] = useState(false);
  const [activeTab, setActiveTab] = useState<'knowledge' | 'recognition'>('recognition');

  const {
    tags = [],
  } = list || {};

  const advancedOptions = [1, 2, 3];

  const tagsTranslated = useTagsToOptionsLang(tags);

  const optionsPlantsList = useMemo(() => {
    if (!plantsList.plantsLists) return [];
    const options = plantsList.plantsLists.map((d: any) => ({
      value: d._id,
      label: d.name,
    }));
    return options;
  }, [plantsList.plantsLists]);

  const QRcount = parseInt(watch('settings.recognitionCount') || 0, 10);
  const QCcount = parseInt(watch('settings.knowledgeCount') || 0, 10);

  const recognitionSettings = watch('settings.recognition');
  const knowledgeSettings = watch('settings.knowledge');
  const recognitionAdvancedSettings = watch('settings.recognitionSetting');
  const knowledgeAdvancedSettings = watch('settings.knowledgeSetting');

  const Rlabels = {
    QRU: {
      label: 'QRU',
      range: 'QRU',
      name: 'QRU',
    },
    QRM: {
      label: 'QRM',
      range: 'QRM',
      name: 'QRM',
    },
    QRA: {
      label: 'QRA',
      range: 'A',
      name: 'Appariement (A)',
    },
    QRT: {
      label: 'QRT',
      range: 'RC',
      name: 'Réponse courte (RC)',
    },
  };

  const Klabels = {
    QRU: {
      label: 'QRU',
      range: 'QRU',
      name: 'QRU',
    },
    QRM: {
      label: 'QRM',
      range: 'QRM',
      name: 'QRM',
    },
    QRA: {
      label: 'QRA',
      range: 'A',
      name: 'Appariement (A)',
    },
  };

  const QRUlabelsR = {
    QRU_R_PICTURES: {
      label: 'P',
      range: 'P',
      name: 'Photo (P)',
    },
    QRU_R_NAMES: {
      label: 'N',
      range: 'N',
      name: 'Noms (N)',
    },
    QRU_R_NAMES_HINT: {
      label: 'NI',
      range: 'NI',
      name: 'Noms + indices (NI)',
    },
    QRU_R_NAMES_TRUE_FALSE: {
      label: 'VF',
      range: 'VF',
      name: 'Vrai Faux (VF)',
    },
  };

  const QRUlabelsC = {
    QRU_C_CHARACTER: {
      label: 'C',
      range: 'C',
      name: 'Caractère (C)',
    },
    QRM_C_CHARACTERS: {
      label: 'CS',
      range: 'CS',
      name: 'Caractères (CS)',
    },
    QRU_C_NAMES_CONTEXT_PICTURES: {
      label: 'NP',
      range: 'NP',
      name: 'Noms + Photos (NP)',
    },
    QRU_C_TRUE_FALSE: {
      label: 'VF',
      range: 'VF',
      name: 'Vrai Faux (VF)',
    },
    QRU_C_SELECT: {
      label: 'CU',
      range: 'CU',
      name: 'Choix unique',
    },
    QRU_C_SELECTS: {
      label: 'CMS',
      range: 'CMS',
      name: 'Choix multiples',
    },
  };

  const QRMlabelsC = {
    QRM_C_CHARACTERS: {
      label: 'CS',
      range: 'CS',
      name: 'Caractères (CS)',
    },
    QRM_C_NAMES_CONTEXT_PICTURES: {
      label: 'NP',
      range: 'NP',
      name: 'Noms + Photos (NP)',
    },
  };

  const QRAlabelsR = {
    QRA_R_PICTURES: {
      label: 'P',
      range: 'P',
      name: 'Photo (P)',
    },
    QRA_R_NAMES_PICTURES: {
      label: 'NP',
      range: 'NP',
      name: 'Noms + Photos (N)',
    },
    QRA_R_TAXONOMY: {
      label: 'T',
      range: 'T',
      name: 'Taxonomie (T)',
    },
  };

  function remainderFunction(inputs: InputsProps, total: number) {
    return total % Object.keys(inputs).length;
  }

  // function to divide the questions equally and create range values
  function distribution(inputs: InputsProps, totalDistributable: number, remainder: number) {
    const obj: any = { ...inputs };
    const totalLessRemainder = totalDistributable - remainder;
    const equalNumber = totalLessRemainder / Object.keys(inputs).length;
    const newEntries = Object.entries(obj);
    newEntries.forEach(([key], i: number) => {
      if (i === newEntries.length - 1) {
        obj[key].value = equalNumber + remainder;
      } else {
        obj[key].value = equalNumber;
      }
    });

    return obj;
  }

  function handleChangeRecognitionAdvancedSettings(isAdvenced: boolean) {
    if (!isAdvenced) return setValue('settings.recognitionSetting', null);
    const settings : any = {

    };
    if (recognitionSettings.QRU) {
      settings.QRU = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
      const minimalRecognitionSettings = {
        QRU_R_PICTURES: { value: recognitionSettings.QRU.value % 4 },
        QRU_R_NAMES: { value: recognitionSettings.QRU.value % 4 },
        QRU_R_NAMES_HINT: { value: recognitionSettings.QRU.value % 4 },
        QRU_R_NAMES_TRUE_FALSE: { value: recognitionSettings.QRU.value % 4 },
      };
      const remainder = remainderFunction(
        minimalRecognitionSettings,
        recognitionSettings.QRU.value,
      );
      const distributedInputs = distribution(
        minimalRecognitionSettings,
        recognitionSettings.QRU.value,
        remainder,
      );
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        console.log(k, value);
        settings.QRU[k] = value;
      });
    }
    if (recognitionSettings.QRM) {
      settings.QRM = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
    }
    if (recognitionSettings.QRA) {
      settings.QRA = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
      const minimalRecognitionSettings = {
        QRA_R_PICTURES: { value: recognitionSettings.QRA.value % 3 },
        QRA_R_NAMES_PICTURES: { value: recognitionSettings.QRA.value % 3 },
        QRA_R_TAXONOMY: { value: recognitionSettings.QRA.value % 3 },
      };
      const remainder = remainderFunction(
        minimalRecognitionSettings,
        recognitionSettings.QRA.value,
      );
      const distributedInputs = distribution(
        minimalRecognitionSettings,
        recognitionSettings.QRA.value,
        remainder,
      );
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        settings.QRA[k] = value;
      });
    }
    if (recognitionSettings.QRT) {
      settings.QRT = {
        pictures: 1,
      };
    }
    return setValue('settings.recognitionSetting', settings);
  }

  function handleChangeKnowledgeAdvancedSettings(isAdvenced: boolean) {
    if (!isAdvenced) return setValue('settings.knowledgeSetting', null);
    const settings : any = {

    };
    if (knowledgeSettings.QRU?.value) {
      settings.QRU = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
      const minimalKnowledgeSettings = {
        QRU_C_CHARACTER: { value: knowledgeSettings.QRU.value % 6 },
        QRM_C_CHARACTERS: { value: knowledgeSettings.QRU.value % 6 },
        QRU_C_NAMES_CONTEXT_PICTURES: { value: knowledgeSettings.QRU.value % 6 },
        QRU_C_TRUE_FALSE: { value: knowledgeSettings.QRU.value % 6 },
        QRU_C_SELECT: { value: knowledgeSettings.QRU.value % 6 },
        QRU_C_SELECTS: { value: knowledgeSettings.QRU.value % 6 },
      };
      const remainder = remainderFunction(
        minimalKnowledgeSettings,
        recognitionSettings.QRU.value,
      );
      const distributedInputs = distribution(
        minimalKnowledgeSettings,
        recognitionSettings.QRU.value,
        remainder,
      );
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        settings.QRU[k] = value;
      });
    }
    if (knowledgeSettings.QRM?.value) {
      settings.QRM = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
      const minimalKnowledgeSettings = {
        QRM_C_CHARACTERS: { value: knowledgeSettings.QRM.value % 2 },
        QRM_C_NAMES_CONTEXT_PICTURES: { value: knowledgeSettings.QRM.value % 2 },
      };
      const remainder = remainderFunction(
        minimalKnowledgeSettings,
        recognitionSettings.QRM.value,
      );
      const distributedInputs = distribution(
        minimalKnowledgeSettings,
        recognitionSettings.QRM.value,
        remainder,
      );
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        settings.QRM[k] = value;
      });
    }
    if (knowledgeSettings.QRA?.value) {
      settings.QRA = {
        pictures: 1,
        distractors: 1,
        picturesProposal: 1,
      };
    }
    return setValue('settings.knowledgeSetting', settings);
  }

  // Handle recognition questions count change
  useEffect(() => {
    if (!recognitionSettings) return;
    let isDistributed = false;
    const minimalRecognitionSettings = {
      QRU: recognitionSettings.QRU,
      QRM: recognitionSettings.QRM,
      QRA: recognitionSettings.QRA,
      QRT: recognitionSettings.QRT,
    };

    const total = Object.values(minimalRecognitionSettings)
      .reduce((acc, curr: any) => acc + curr.value, 0);

    if (total === QRcount) isDistributed = true;

    if (!isDistributed) {
      const remainder = remainderFunction(minimalRecognitionSettings, QRcount);
      const distributedInputs = distribution(minimalRecognitionSettings, QRcount, remainder);
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        setValue(`settings.recognition.${k}`, value);
      });
    }
    if (recognitionAdvancedSettings) {
      const settings: any = {

      };
      if (recognitionSettings.QRU?.value) {
        settings.QRU = recognitionAdvancedSettings?.QRU ? {
          ...recognitionAdvancedSettings.QRU,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
        const minimalRecognitionQRUSettings = {
          QRU_R_PICTURES: { value: recognitionSettings.QRU.value % 4 },
          QRU_R_NAMES: { value: recognitionSettings.QRU.value % 4 },
          QRU_R_NAMES_HINT: { value: recognitionSettings.QRU.value % 4 },
          QRU_R_NAMES_TRUE_FALSE: { value: recognitionSettings.QRU.value % 4 },
        };
        const remainderQRU = remainderFunction(
          minimalRecognitionQRUSettings,
          recognitionSettings.QRU.value,
        );
        const distributedInputsQRU = distribution(
          minimalRecognitionQRUSettings,
          recognitionSettings.QRU.value,
          remainderQRU,
        );
        Object.entries({
          ...distributedInputsQRU,
        }).forEach(([k, value]): any => {
          settings.QRU[k] = value;
        });
      }
      if (recognitionSettings?.QRM?.value) {
        settings.QRM = recognitionAdvancedSettings?.QRM ? {
          ...recognitionAdvancedSettings.QRM,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
      }
      if (recognitionSettings?.QRA?.value) {
        settings.QRA = recognitionAdvancedSettings?.QRA ? {
          ...recognitionAdvancedSettings.QRA,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
        const minimalRecognitionQRASettings = {
          QRA_R_PICTURES: { value: recognitionSettings.QRA.value % 3 },
          QRA_R_NAMES_PICTURES: { value: recognitionSettings.QRA.value % 3 },
          QRA_R_TAXONOMY: { value: recognitionSettings.QRA.value % 3 },
        };
        const remainderQRM = remainderFunction(
          minimalRecognitionQRASettings,
          recognitionSettings.QRA.value,
        );
        const distributedInputsQRA = distribution(
          minimalRecognitionQRASettings,
          recognitionSettings.QRA.value,
          remainderQRM,
        );
        Object.entries({
          ...distributedInputsQRA,
        }).forEach(([k, value]): any => {
          settings.QRA[k] = value;
        });
      }
      if (recognitionSettings?.QRT?.value) {
        settings.QRT = recognitionAdvancedSettings?.QRT ? {
          ...recognitionAdvancedSettings.QRT,
        } : {
          pictures: 1,
        };
      }
      setValue('settings.recognitionSetting', settings);
    }
  }, [
    recognitionSettings?.QRU?.value,
    recognitionSettings?.QRM?.value,
    recognitionSettings?.QRA?.value,
    recognitionSettings?.QRT?.value,
    QRcount,
  ]);

  // Handle knowledge questions count change
  useEffect(() => {
    if (!knowledgeSettings) return;
    let isDistributed = false;
    const total = Object.values(knowledgeSettings)
      .reduce((acc, curr: any) => acc + curr.value, 0);

    if (total === QCcount) isDistributed = true;

    if (!isDistributed) {
      const remainder = remainderFunction(knowledgeSettings, QCcount);
      const distributedInputs = distribution(knowledgeSettings, QCcount, remainder);
      setValue('settings.knowledge', distributedInputs);
    }
    if (knowledgeAdvancedSettings) {
      const settings: any = {

      };
      if (knowledgeSettings.QRU?.value) {
        settings.QRU = knowledgeAdvancedSettings?.QRU ? {
          ...knowledgeAdvancedSettings.QRU,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
        const minimalQRUSettings = {
          QRU_C_CHARACTER: { value: knowledgeSettings.QRU.value % 6 },
          QRM_C_CHARACTERS: { value: knowledgeSettings.QRU.value % 6 },
          QRU_C_NAMES_CONTEXT_PICTURES: { value: knowledgeSettings.QRU.value % 6 },
          QRU_C_TRUE_FALSE: { value: knowledgeSettings.QRU.value % 6 },
          QRU_C_SELECT: { value: knowledgeSettings.QRU.value % 6 },
          QRU_C_SELECTS: { value: knowledgeSettings.QRU.value % 6 },
        };
        const remainderQRU = remainderFunction(
          minimalQRUSettings,
          knowledgeSettings.QRU.value,
        );
        const distributedInputsQRU = distribution(
          minimalQRUSettings,
          knowledgeSettings.QRU.value,
          remainderQRU,
        );
        Object.entries({
          ...distributedInputsQRU,
        }).forEach(([k, value]): any => {
          settings.QRU[k] = value;
        });
      }
      if (knowledgeSettings?.QRM?.value) {
        settings.QRM = knowledgeAdvancedSettings?.QRM ? {
          ...knowledgeAdvancedSettings.QRM,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
        const minimalQRMSettings = {
          QRM_C_CHARACTERS: { value: knowledgeSettings.QRM.value % 2 },
          QRM_C_NAMES_CONTEXT_PICTURES: { value: knowledgeSettings.QRM.value % 2 },
        };
        const remainderQRM = remainderFunction(
          minimalQRMSettings,
          knowledgeSettings.QRM.value,
        );
        const distributedInputsQRM = distribution(
          minimalQRMSettings,
          knowledgeSettings.QRM.value,
          remainderQRM,
        );
        Object.entries({
          ...distributedInputsQRM,
        }).forEach(([k, value]): any => {
          settings.QRM[k] = value;
        });
      }
      if (knowledgeSettings?.QRA?.value) {
        settings.QRA = knowledgeAdvancedSettings?.QRA ? {
          ...knowledgeAdvancedSettings.QRA,
        } : {
          pictures: 1,
          distractors: 1,
          picturesProposal: 1,
        };
      }
      setValue('settings.knowledgeSetting', settings);
    }
  }, [knowledgeSettings, QCcount]);

  return (
    <div className={styles.form}>
      <div className={styles.mobile}>
        <p>{t('mobileMessage', { ns: 'createQuiz' })}</p>
      </div>
      <section>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            label={t('fields.name', { ns: 'createQuiz' })}
            placeholder={t('fields.placeholderName', { ns: 'createQuiz' })}
            rules={{
              required: t('fields.requiredName', { ns: 'createQuiz' }),
            }}
          />
        </div>
        <div className={styles.containerField}>
          <h3>{t('fields.description', { ns: 'createQuiz' })}</h3>
          <Textarea
            name="description"
            control={control}
            placeholder={t('fields.placeholderDesc', { ns: 'createQuiz' })}
            rules={{
              required: t('fields.requiredDesc', { ns: 'createQuiz' }),
            }}
          />
        </div>
        <div className={styles.tags}>
          <h3>{t('fields.tags')}</h3>
          <SelectMultiple
            name="tags"
            control={control}
            options={tagsTranslated}
          />
        </div>
      </section>
      <hr />
      {/* List selection */}
      <section>
        <div className={styles.plants}>
          <h4>
            {t('fields.listPlants.title', { ns: 'createQuiz' })}
          </h4>
          <div className={`${styles.choose} ${styles.containerField}`}>
            <button
              className={(watch('isTepikPlants') || watch('plantsList') || isSelectListPLant) ? '' : styles.active}
              onClick={() => {
                setValue('isTepikPlants', false);
                setValue('plantsList', null);
                setSelectListPlants(false);
              }}
            >
              {t('fields.listPlants.optionAll', { ns: 'createQuiz' })}
            </button>
            <button
              className={watch('isTepikPlants') ? styles.active : ''}
              onClick={() => {
                setValue('isTepikPlants', true);
                setValue('plantsList', null);
                setSelectListPlants(false);
              }}
            >
              {t('fields.listPlants.optionTepik', { ns: 'createQuiz' })}
            </button>
            <button
              className={watch('plantsList') || isSelectListPLant ? styles.active : ''}
              onClick={() => {
                setValue('isTepikPlants', false);
                setSelectListPlants(true);
              }}
            >
              {t('fields.listPlants.optionCustom', { ns: 'createQuiz' })}
            </button>
          </div>
          {(isSelectListPLant || watch('plantsList'))
            && <div className={styles.selectPlants}>
                <div className={styles.select}>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="plantsList"
                    control={control}
                    label={t('fields.listPlants.select', { ns: 'createQuiz' })}
                    options={optionsPlantsList}
                    isClearable
                  />

                </div>
                <div className={styles.containerField}>
                  <button
                    type="button"
                    className={styles.create}
                    onClick={() => createList()}
                  >
                  {t('fields.listPlants.create', { ns: 'createQuiz' })}
                  </button>
                </div>
              </div>
            {!watch('plantsList') && <ErrorField message={t('requiredField', { ns: 'global' })} />}
            </div>
          }
          <h4>
            {t('fields.plantsName', { ns: 'createQuiz' })}
          </h4>
          <div className={`${styles.typesPlantName} ${styles.containerField}`}>
            <ListTags
              name='typePlantName'
              control={control}
              tags={[
                { label: t('fields.commonNames', { ns: 'createQuiz' }), value: 'common_names' },
                { label: t('fields.scientificNames', { ns: 'createQuiz' }), value: 'scientific_name' },
                { label: t('fields.randomName', { ns: 'createQuiz' }), value: 'random' },
              ]}
            />
          </div>
        </div>
      </section>
      {/* Questions count */}
      <hr />
      <section>
        {/* {difficulty
          && <div className={styles.difficulty}>
            <h4>{t('fields.difficulty.title')}</h4>
            <p>{t('fields.difficulty.level')} : {difficulty
            && <span style={{ color: difficulitiesColors[watch('difficulty')]?.color }}>
              {t(`fields.difficulty.${difficulty}`)}</span>}
            </p>
            <div className={styles.select}>
              {difficulitiesColors.map((a:any, i: number) => (
                <>
                  <div
                    key={a.label}
                    className={styles.level}
                    data-tooltip-id={a.label}
                    data-tooltip-content={t(`fields.difficulty.${a.label}`)}
                    style={{
                      border: `1px solid ${a.color}`,
                      backgroundColor: i === watch('difficulty') ? a.color : 'transparent',
                      color: i === watch('difficulty') ? '#FFFFFF' : a.color,
                    }}
                  >
                  </div>
                  <Tooltip id={a.label}/>
                </>
              ))}
            </div>
        </div>
        } */}
        <div className={styles.questionsCount}>
          <h4>{t('questionsDistribution')}</h4>
          <div className={styles.fields}>
            <div className={styles.containerField}>
              <InputNumber
                name="settings.recognitionCount"
                control={control}
                label={t('fields.questionsCountReco', { ns: 'createQuiz' })}
                defaultValue={0}
                min='0'
                rules={{
                  required: t('fields.requiredRecognitionCount', { ns: 'createQuiz' }),
                  validate: (value: number) => {
                    const knowledgeCount = getValues('settings.knowledgeCount') || 0;
                    return (knowledgeCount + value) === 0
                      ? t('fields.notZeroCount', { ns: 'createQuiz' })
                      : true;
                  },
                }}
              />
            </div>
            <div className={styles.containerField}>
              <InputNumber
                name="settings.knowledgeCount"
                control={control}
                label={t('fields.questionsCountKnow', { ns: 'createQuiz' })}
                defaultValue={0}
                min='0'
                rules={{
                  required: t('fields.requiredKnowledgeCount', { ns: 'createQuiz' }),
                  validate: (value: number) => {
                    const recognitionCount = getValues('settings.recognitionCount') || 0;
                    return (recognitionCount + value) === 0
                      ? t('fields.notZeroCount', { ns: 'createQuiz' })
                      : true;
                  },
                }}
              />
            </div>
          </div>
          <div>
            <div className={styles['count-container']}>
              {/* <h4>{t('questions')}</h4> */}
              <div>
                <p>{t('fields.questionsCount', { ns: 'createQuiz' })}</p>
                <span className={styles.count}>{QRcount + QCcount}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.tabs}>
        <ul>
          <li
            className={activeTab === 'recognition' ? styles.actived : ''}
            onClick={() => setActiveTab('recognition')}
          >
            {t('fields.recognition')}
            </li>
          <li
            className={activeTab === 'knowledge' ? styles.actived : ''}
            onClick={() => setActiveTab('knowledge')}
          >
            {t('fields.knowledge')}
          </li>
        </ul>
      </div>
      {/* Recognition */}
      <section style={activeTab === 'recognition' ? { display: 'block' } : { display: 'none' }}>
          <div className={styles.recognition}>
            <div>
              <h5>{t('fields.questionsTypes', { ns: 'createQuiz' })}</h5>
              {recognitionSettings && (
                <div className={styles.distribution}>
                  <QuestionsDistribution
                    questionsType="Question de reconnaissance"
                    idInput="recognition_questions"
                    totalQuestions={QRcount}
                    labels={Rlabels}
                    inputs={{
                      QRU: recognitionSettings.QRU,
                      QRM: recognitionSettings.QRM,
                      QRA: recognitionSettings.QRA,
                      QRT: recognitionSettings.QRT,
                    }}
                    setInputs={(obj: any) => {
                      setValue('settings.recognition.QRU', obj.QRU);
                      setValue('settings.recognition.QRM', obj.QRM);
                      setValue('settings.recognition.QRA', obj.QRA);
                      setValue('settings.recognition.QRT', obj.QRT);
                    }}
                  />
                </div>
              )}
              {recognitionAdvancedSettings && <div style={{ marginBottom: 20 }}><hr /></div>}
              <button
                className={`${styles.advancedModeButton} secondary`}
                onClick={
                  () => handleChangeRecognitionAdvancedSettings(!recognitionAdvancedSettings)}
              >
                {recognitionAdvancedSettings ? t('simpleMode', { ns: 'createQuiz' }) : t('advancedMode', { ns: 'createQuiz' })}
              </button>
              {recognitionAdvancedSettings
              && <>
                {recognitionSettings?.QRU?.value > 0 && recognitionAdvancedSettings?.QRU
                  && <div className={styles.distribution}>
                    <AdvancedDistribution
                      questionsType="advanced.QRU"
                      idInput="recognition_questions_QRU"
                      total={recognitionSettings.QRU.value}
                      labels={QRUlabelsR}
                      inputs={{
                        QRU_R_PICTURES: recognitionAdvancedSettings?.QRU?.QRU_R_PICTURES,
                        QRU_R_NAMES: recognitionAdvancedSettings?.QRU?.QRU_R_NAMES,
                        QRU_R_NAMES_HINT: recognitionAdvancedSettings?.QRU?.QRU_R_NAMES_HINT,
                        QRU_R_NAMES_TRUE_FALSE: recognitionAdvancedSettings?.QRU?.QRU_R_NAMES_TRUE_FALSE,
                      }}
                      setInputs={(obj: any) => {
                        setValue('settings.recognitionSetting.QRU', {
                          ...recognitionAdvancedSettings?.QRU,
                          ...obj,
                        });
                      }}
                      handleChangeOptions={(name: string, value: number) => setValue(`settings.recognitionSetting.QRU.${name}`, value)}
                      options={[
                        {
                          name: 'pictures',
                          value: recognitionAdvancedSettings?.QRU?.pictures,
                          options: advancedOptions,
                        },
                        {
                          name: 'distractors',
                          value: recognitionAdvancedSettings?.QRU?.distractors,
                          options: advancedOptions,
                        },
                        {
                          name: 'picturesProposal',
                          value: recognitionAdvancedSettings?.QRU?.picturesProposal,
                          options: advancedOptions,
                        },
                      ]}
                    />
                </div>
                }
                {recognitionSettings?.QRM?.value > 0 && recognitionAdvancedSettings?.QRM
                  && <div className={styles.distribution}>
                    <AdvancedDistribution
                      questionsType="advanced.QRM"
                      idInput="recognition_questions_QRM"
                      total={recognitionSettings?.QRM?.value}
                      labels={null}
                      handleChangeOptions={(name: string, value: number) => setValue(`settings.recognitionSetting.QRM.${name}`, value)}
                      options={[
                        {
                          name: 'pictures',
                          value: recognitionAdvancedSettings?.QRM?.pictures,
                          options: advancedOptions,
                        },
                        {
                          name: 'distractors',
                          value: recognitionAdvancedSettings?.QRM?.distractors,
                          options: advancedOptions,
                        },
                        {
                          name: 'picturesProposal',
                          value: recognitionAdvancedSettings?.QRM?.picturesProposal,
                          options: advancedOptions,
                        },
                      ]}
                    />
                </div>
                }
                {recognitionSettings?.QRA?.value > 0 && recognitionAdvancedSettings?.QRA
                  && <div className={styles.distribution}>
                    <AdvancedDistribution
                      questionsType="advanced.QRA"
                      idInput="recognition_questions_QRA"
                      total={recognitionSettings?.QRA?.value}
                      labels={QRAlabelsR}
                      inputs={{
                        QRA_R_PICTURES: recognitionAdvancedSettings?.QRA?.QRA_R_PICTURES,
                        QRA_R_NAMES_PICTURES:
                          recognitionAdvancedSettings?.QRA?.QRA_R_NAMES_PICTURES,
                        QRA_R_TAXONOMY: recognitionAdvancedSettings?.QRA?.QRA_R_TAXONOMY,
                      }}
                      setInputs={(obj: any) => {
                        setValue('settings.recognitionSetting.QRA', {
                          ...recognitionAdvancedSettings?.QRA,
                          ...obj,
                        });
                      }}
                      handleChangeOptions={(name: string, value: number) => setValue(`settings.recognitionSetting.QRA.${name}`, value)}
                      options={[
                        {
                          name: 'pictures',
                          value: recognitionAdvancedSettings?.QRA?.pictures,
                          options: advancedOptions,
                        },
                        {
                          name: 'distractors',
                          value: recognitionAdvancedSettings?.QRA?.distractors,
                          options: advancedOptions,
                        },
                        {
                          name: 'picturesProposal',
                          value: recognitionAdvancedSettings?.QRA?.picturesProposal,
                          options: advancedOptions,
                        },
                      ]}
                    />
                </div>
                }
                {recognitionSettings?.QRT?.value > 0 && recognitionAdvancedSettings?.QRT
                  && <div className={styles.distribution}>
                    <AdvancedDistribution
                      questionsType="advanced.QRT"
                      idInput="recognition_questions_QRT"
                      total={recognitionSettings?.QRT?.value}
                      inputs={null}
                      handleChangeOptions={(name: string, value: number) => setValue(`settings.recognitionSetting.QRT.${name}`, value)}
                      options={[
                        {
                          name: 'pictures',
                          value: recognitionAdvancedSettings?.QRT?.pictures,
                          options: advancedOptions,
                        },
                      ]}
                    />
                </div>
                }
              </>
              }
            </div>
          </div>
      </section>
      <section style={activeTab === 'knowledge' ? { display: 'block' } : { display: 'none' }}> <div className={styles.knowledge}>
        <div>
        <h5>{t('fields.questionsTypes', { ns: 'createQuiz' })}</h5>
        {knowledgeSettings && (
          <div className={styles.distribution}>
            <QuestionsDistribution
              questionsType="Question de connaissance"
              idInput="knowledge_questions"
              totalQuestions={QCcount}
              labels={Klabels}
              inputs={knowledgeSettings}
              setInputs={(obj: any) => {
                setValue('settings.knowledge', obj);
              }}
            />
          </div>
        )}
         {knowledgeAdvancedSettings && <div style={{ marginBottom: 20 }}><hr /></div>}
          <button
            className={`${styles.advancedModeButton} secondary`}
            onClick={
              () => handleChangeKnowledgeAdvancedSettings(!knowledgeAdvancedSettings)}
          >
            {knowledgeAdvancedSettings ? t('simpleMode', { ns: 'createQuiz' }) : t('advancedMode', { ns: 'createQuiz' })}
          </button>
          {knowledgeAdvancedSettings
              && <>
              {knowledgeSettings?.QRU?.value > 0 && knowledgeAdvancedSettings?.QRU
                && <div className={styles.distribution}>
                  <AdvancedDistribution
                    questionsType="advanced.QRU"
                    idInput="recognition_questions_QRU"
                    total={knowledgeSettings.QRU.value}
                    labels={QRUlabelsC}
                    inputs={{
                      QRU_C_CHARACTER: knowledgeAdvancedSettings?.QRU?.QRU_C_CHARACTER,
                      QRM_C_CHARACTERS: knowledgeAdvancedSettings?.QRU?.QRM_C_CHARACTERS,
                      QRU_C_NAMES_CONTEXT_PICTURES: knowledgeAdvancedSettings?.QRU?.QRU_C_NAMES_CONTEXT_PICTURES,
                      QRU_C_TRUE_FALSE: knowledgeAdvancedSettings?.QRU?.QRU_C_TRUE_FALSE,
                      QRU_C_SELECT: knowledgeAdvancedSettings?.QRU?.QRU_C_SELECT,
                      QRU_C_SELECTS: knowledgeAdvancedSettings?.QRU?.QRU_C_SELECTS,
                    }}
                    setInputs={(obj: any) => {
                      setValue('settings.knowledgeSetting.QRU', {
                        ...knowledgeAdvancedSettings?.QRU,
                        ...obj,
                      });
                    }}
                    handleChangeOptions={(name: string, value: number) => setValue(`settings.knowledgeSetting.QRU.${name}`, value)}
                    options={[
                      {
                        name: 'pictures',
                        value: knowledgeAdvancedSettings?.QRU?.pictures,
                        options: advancedOptions,
                      },
                      {
                        name: 'distractors',
                        value: knowledgeAdvancedSettings?.QRU?.distractors,
                        options: advancedOptions,
                      },
                      {
                        name: 'picturesProposal',
                        value: knowledgeAdvancedSettings?.QRU?.picturesProposal,
                        options: advancedOptions,
                      },
                    ]}
                  />
              </div>
              }
               {knowledgeSettings?.QRM?.value > 0 && knowledgeAdvancedSettings?.QRM
                && <div className={styles.distribution}>
                  <AdvancedDistribution
                    questionsType="advanced.QRM"
                    idInput="recognition_questions_QRM"
                    total={knowledgeSettings.QRM.value}
                    labels={QRMlabelsC}
                    inputs={{
                      QRM_C_CHARACTERS: knowledgeAdvancedSettings?.QRM?.QRM_C_CHARACTERS,
                      QRM_C_NAMES_CONTEXT_PICTURES: knowledgeAdvancedSettings?.QRM?.QRM_C_NAMES_CONTEXT_PICTURES,
                    }}
                    setInputs={(obj: any) => {
                      setValue('settings.knowledgeSetting.QRM', {
                        ...knowledgeAdvancedSettings?.QRM,
                        ...obj,
                      });
                    }}
                    handleChangeOptions={(name: string, value: number) => setValue(`settings.knowledgeSetting.QRM.${name}`, value)}
                    options={[
                      {
                        name: 'pictures',
                        value: knowledgeAdvancedSettings?.QRM?.pictures,
                        options: advancedOptions,
                      },
                      {
                        name: 'distractors',
                        value: knowledgeAdvancedSettings?.QRM?.distractors,
                        options: advancedOptions,
                      },
                      {
                        name: 'picturesProposal',
                        value: knowledgeAdvancedSettings?.QRM?.picturesProposal,
                        options: advancedOptions,
                      },
                    ]}
                  />
              </div>
              }
               {knowledgeSettings?.QRA?.value > 0 && knowledgeAdvancedSettings?.QRA
                  && <div className={styles.distribution}>
                    <AdvancedDistribution
                      questionsType="advanced.QRA"
                      idInput="knowl_questions_QRA"
                      total={knowledgeSettings?.QRA?.value}
                      inputs={null}
                      handleChangeOptions={(name: string, value: number) => setValue(`settings.knowledgeSetting.QRA.${name}`, value)}
                      options={[
                        {
                          name: 'pictures',
                          value: knowledgeAdvancedSettings?.QRA?.pictures,
                          options: advancedOptions,
                        },
                        {
                          name: 'distractors',
                          value: knowledgeAdvancedSettings?.QRA?.distractors,
                          options: advancedOptions,
                        },
                        {
                          name: 'picturesProposal',
                          value: knowledgeAdvancedSettings?.QRA?.picturesProposal,
                          options: advancedOptions,
                        },
                      ]}
                    />
                </div>
                }
            </>
          }
        </div>
        </div>
      </section>
    </div>
  );
};

export default QuizForm;
